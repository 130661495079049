// 08012022 - Gaurav - CA-953: Top Nav - Analysis, Coding and UT
// 16092022 - Gaurav - CA-1491: Remove header menu outline that appears on browser refresh or load (a element inside nav-item)
// 16122022 - Gaurav - CA-1650: Show logo left to avatar button along with org name
* {
  box-sizing: border-box;
}

// Animation to fade in the dropdown menus
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.app-grand-container {
  height: $hundred-percent;
  width: $hundred-percent;
  overflow: auto;
  // background: rgba($theme-bg);
  background-color: $dashboard-bgColor;

  .app-header {
    top: 0px;
    position: sticky;
    z-index: 999;
    // background-color: $header-bgcolor;

    app-network-info {
      padding: 10px 0;
    }

    nav {
      background: $primary-color;
    }
    .user-rounded-circle {
      .rounded-circle {
        background-color: $primary-color;
        color: $color-white;
      }
    }

    // .navbar-nav .nav-link {
    //   color: $color-white;
    // }

    .nav-item {
      font-size: 13px;
      // color: $color-white;

      a {
        outline: 0;

        &:focus {
          outline: 0;
        }
      }
    }

    // Assigning the above animation to all dropdown menus
    .dropdown-menu {
      &.show {
        -webkit-animation: fadeIn 0.3s alternate; /* Safari 4.0 - 8.0 */
        animation: fadeIn 0.3s alternate;
      }

      .dropdown-item {
        font-size: 13px;
        // color: $color-white;
        // background-color: $header-bgcolor;
      }
    }

    // Mega Menu adjustments
    .nav-item.dropdown.dropdown-mega {
      position: static;
      .dropdown-menu {
        width: 90%;
        top: auto;
        left: 5%;
      }
    }
    //theme selection bucket
    .mobile-theme {
      right: 65px;
      color: white;
      position: absolute;
      display: inline-block;
      &.active {
        display: none;
      }
    }
    // Complete styles for main navbar toggler and hamburger menu
    .navbar-toggler {
      border: none;
      padding: 0;
      outline: none;
      &:focus {
        box-shadow: none;
      }

      .hamburger-toggle {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 50px;
        z-index: 11;
        // float: right;

        .hamburger {
          position: absolute;
          transform: translate(-50%, -50%) rotate(0deg);
          left: 50%;
          top: 50%;
          width: 50%;
          height: 50%;
          pointer-events: none;
          span {
            width: 100%;
            height: 4px;
            position: absolute;
            background: white;
            border-radius: 2px;
            z-index: 1;
            transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1),
              background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1),
              all 0.2s ease-in-out;
            left: 0px;
            &:first-child {
              top: 10%;
              transform-origin: 50% 50%;
              transform: translate(0% -50%) !important;
            }
            &:nth-child(2) {
              top: 50%;
              transform: translate(0, -50%);
            }
            &:last-child {
              left: 0px;
              top: auto;
              bottom: 10%;
              transform-origin: 50% 50%;
            }
          }
          &.active {
            span {
              position: absolute;
              margin: 0;
              &:first-child {
                top: 45%;
                transform: rotate(45deg) !important;
              }
              &:nth-child(2) {
                left: 50%;
                width: 0px;
              }
              &:last-child {
                top: 45%;
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }

    .icons {
      display: inline-flex;
      margin-left: auto;
      a {
        transition: all 0.2s ease-in-out;
        padding: 0.2rem 0.4rem;
        color: #ccc !important;
        text-decoration: none;
        &:hover {
          color: white;
          text-shadow: 0 0 30px white;
        }
      }
    }

    .menu-title {
      .mat-icon {
        color: $color-white;
      }
    }
    .menu-right {
      // &:hover,
      // &.active {
      //   .mat-icon {
      //     color: $primary-color;
      //   }
      // }
      .mat-icon {
        color: white;
      }

      .logged-user-circle {
        padding: 15px;
        border-radius: 50%;
        background: white;
        line-height: 0;
        position: relative;
        bottom: 4px;
        right: 2px;

        // 07092022 - Gaurav - CA-1470: Add selected indicator to User Settings round button
        &:hover,
        &.active {
          box-shadow: 0 1px 20px 0 $brand-color-light,
            0 1px 20px 0 $brand-color-light;
        }
      }

      .logged-user-circle::after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }

      .logged-user-circle-txt {
        position: absolute;
        bottom: 50%;
        width: 100%;
        text-align: center;
        color: $primary-color;
        font-weight: bold;
        right: 1px;
        top: 1rem;
        font-size: 1rem;
      }

      // .mat-menu {
      //   .user-name {
      //     // color: $color-white;
      //     color: $primary-color;
      //     font-weight: 100;
      //     font-size: 1rem;
      //     // font-size: 14px;

      //     &.offline {
      //       color: $table-hover-colour;
      //       font-weight: 100;
      //       font-size: 1rem;
      //       // font-size: 14px;

      //       mat-icon {
      //         font-size: 1.4rem;
      //         color: $table-hover-colour;
      //       }
      //     }
      //   }

      //   .org-name {
      //     color: rgba($accent-color, 1);
      //     font-weight: 100;
      //     font-size: 0.75rem;
      //     // font-size: 11px;

      //     &.offline {
      //       color: $table-hover-colour;
      //       font-weight: 100;
      //       font-size: 0.75rem;
      //       // font-size: 11px;
      //     }
      //   }
      // }
    }

    .user-name {
      // color: $color-white;
      color: white;
      font-weight: 100;
      font-size: 1rem;

      &.offline {
        color: $table-hover-colour;
        font-weight: 100;
        font-size: 1rem;

        mat-icon {
          font-size: 1.4rem;
          color: $table-hover-colour;
        }
      }
    }

    .org-name {
      color: rgba($accent-color, 1);
      font-weight: 100;
      font-size: 0.75rem;

      &.offline {
        color: $table-hover-colour;
        font-weight: 100;
        font-size: 0.75rem;
      }
    }

    .mat-mdc-icon-button {
      // width: 30px;
      // height: 30px !important;
      line-height: 1px;
      mat-icon {
        font-size: 1.2rem;
        line-height: 18px;
      }
    }

    .app-logo {
      text-align: $align-center;
      position: relative;
      background: white;
      border-radius: 5px;
      margin-right: 5px;
      .navbar-brand {
        margin: auto;
        padding-right: 5px;
        padding-left: 5px;
      }
      img {
        margin: 5px auto;
        width: auto;
        height: 30px;
        object-fit: contain;
      }

      &.secondary > img {
        margin: 0;
        border-radius: 5px;
      }

      .menu-lock-icon {
        position: absolute;
        right: 2px;
        top: 5px;
        font-size: 24px;
        cursor: pointer;
        @include theme-text-color("theme", $menu-lock-icon-color, 1);
      }
    }
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 48px;
  }
  mat-expansion-panel {
    margin: 0 auto;
  }
  mat-accordion
    > .mat-expansion-panel
    > .mat-expansion-panel-content
    > .mat-expansion-panel-body {
    padding: 0 !important;
  }
}
