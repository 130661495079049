//Login page styles start
.login-grand-container {
  background: rgba($color: #000000, $alpha: 1);
  width: $hundred-percent;
  height: $hundred-percent;
  position: relative;

  &.login-page {
    // background: $login-bgcolor;
    // background: rgb($theme-bg);
    // background: #1C61A1;
    .mat-mdc-card {
      background: rgb($theme-bg);
      padding: 10px;

      form {
        div.form-control-container {
          .input-group {
            span.input-group-text {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
     
    }
  }

  .login-container {
    width: $hundred-percent;
    margin: 0;
    // position: absolute;
    position: relative;
    top: math.div($hundred-percent, 2);
    left: math.div($hundred-percent, 2);
    -ms-transform: translate(
      -(math.div($hundred-percent, 2)),
      -(math.div($hundred-percent, 2))
    );
    transform: translate(
      -(math.div($hundred-percent, 2)),
      -(math.div($hundred-percent, 2))
    );
    display: flex;
    flex-direction: column;
    justify-content: $align-center;
    align-items: $align-center;
    // background-color: $primary-color;
    &-box {
      .form-control-container:has(:invalid:not(:focus)) {
        animation: shake 0.2s;
      }

      @keyframes shake {
        0%,
        100% {
          transform: translateX(0);
        }
        20%,
        40%,
        60%,
        80% {
          transform: translateX(-2%);
        }
        10%,
        30%,
        50%,
        70%,
        90% {
          transform: translateX(2%);
        }
      }

      //width: 40%;
      .logo {
        text-align: $align-center;
        .password-message {
          text-align: $align-center;
          color: #1c61a1;
        }
        img {
          width: math.div($hundred-percent, 2) + 10%;
          object-fit: contain;
          padding: 21px 0;
        }
      }
      .forgot-container {
        width: $hundred-percent;
        margin-top: 5px;
        .forgot-header {
          font-size: 12px;
          color: $forgot-header-color;
          &:hover {
            color: $forgot-header-hover-color;
          }
        }
        .flex-style {
          flex-direction: row;
        }
      }

      .forgot-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: $align-center;
        .forgot-btn {
          height: fit-content;
          padding: 0;
          margin: 0 11px;
        }
      }
    }
  }
  mat-form-field {
    width: $hundred-percent;
    margin-bottom: 10px;
    &:first-of-type {
      margin-top: 10px;
    }
  }

  .submit-btn {
    display: flex;
    justify-content: space-between;
    button {
      width: $hundred-percent;
      margin-top: 10px;
    }

    .default-button {
      background-color: #145993;
      color: white;
      &:hover {
        // background-color: $btn-hover;
        filter: brightness(85%);
      }
    }
  }
}

// 18072022 - Gaurav - CA-1336: Add background to login page
.login-grand-container::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-image: var(--image-url);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

@media screen and (max-width: 500px) {
  .login-grand-container {
    .login-container {
      width: 90%;
    }
  }
}
