// 19102022 - Gaurav - CA-1554: Modify Message Campaign and Customer Filter to use custom Bootstrap fields
.filter-criteria-table {
  &.table thead th {
    background: rgba($menu-textcolor, 0.2);
    border: none;
  }
  &.table-bordered td,
  .table-bordered th {
    border: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field,
  .mat-form-field-flex {
    padding: 0 !important;
    background: none;
  }
  .and-or {
    text-align: $align-center;
    vertical-align: middle;
    color: rgba($menu-textcolor);
    a {
      position: absolute;
      right: 0;
    }
    span.me-2 {
      color: rgba($menu-textcolor);
    }
    button {
      display: block;
    }
    .btn-action {
      margin-bottom: 5px;
    }
  }

  td {
    min-width: 100px;
  }

  td.startParamTd {
    width: 100px;
    position: relative;
    mat-form-field,
    app-reactive-form-field {
      width: 70px;
      pointer-events: none;
      input {
        pointer-events: none;
      }
    }
    .arrow-up {
      top: 1px;
    }
    .arrow-down {
      top: 15px;
    }
    .arrow-up,
    .arrow-down {
      position: absolute;
      right: 11px;
      color: rgba(var(--third-color));
      padding: 0 5px;
      cursor: pointer;
      &:hover {
        color: $color-danger;
      }
    }
  }
}
.form-check-label {
  white-space: nowrap;
}

.event-timeZone {
  padding: 0.5rem 1rem;
  padding-bottom: 3px;
  span.time {
    color: #ababab;
  }
  button {
    float: right;
    line-height: 25px;
    top: -4px;
    color: rgba($menu-textcolor);
  }
  .back-btn {
    text-align: right;
  }
}
mat-icon.double-arrow {
  transform: rotate(180deg);
  cursor: pointer;
  color: $primary-color;
  float: right;
  margin-top: 20px;
}
.viewResponseDisabled {
  //background: #fbecef !important;
  pointer-events: none;
  mat-icon {
    color: #f6c5cd !important;
  }
}
.card-container {
  .campaign-form {
    h4 {
      margin-bottom: 5px !important;
      color: $color-gray;
    }
  }
}

.timeZone {
  right: 20px;
  position: absolute;
  margin-top: -20px;
  color: $color-gray;
  span {
    color: $color-black;
  }
}
.messageDisabled {
  background: #c7d7c782 !important;
  pointer-events: none;
  mat-icon {
    color: #477b4766 !important;
  }
}
.p-buttonset button {
  padding: 0;
  margin: 0;
  line-height: 1.8;
  font-size: 12px;
}
.campaign-recur {
  border: 1px solid #d6d6d6;
  margin: 0px;
  padding: 15px 0;
  background: rgba($theme-bg);
  input[type="radio"] {
    margin-right: 5px;
  }
  .scheduleBox {
    .main {
      background: rgba($menu-textcolor, 0.1);
      padding: 0.5rem 1rem;
    }
  }
}
.events-list {
  .mat-mdc-button {
    background: none;
  }
}
//Schedule preview dialog styles start
.scheduleDialog {
  ul {
    list-style: none;
    padding: 0;
    li {
      padding: 5px 0;
      position: relative;
      &:hover {
        background: $hover-bg-color;
        color: $primary-color;
      }
      mat-icon {
        position: absolute;
        font-size: 18px;
      }
      span {
        margin-left: 25px;
      }
    }
  }
}
//Schedule preview dialog styles end
