.app-tree-div{
  margin-right: 0;
  margin-left: 0;
  display: flex;
  width: 100%
}
.app-tree-container {
  width: 25%;
  &.tree-container{
    display: flex;
            overflow: auto;
            white-space: nowrap;
            border-style: hidden;
            padding-right: 0;
            padding-left: 0;
  }
}
.app-tree-node {
  padding: 3px;
  mat-icon {
    padding: 5px;
    display: inherit;
    padding-right: 0.5rem;
  }
}
