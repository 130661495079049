.do-notify-color {
  color: $primary-color;

  &.selected-border {
    border: 1px dashed $primary-color;
    padding: 0 10px;
  }
}

.do-notify-negative-color {
  color: red;

  &.selected-border {
    border: 1px dashed red;
    padding: 0 10px;
  }
}

.do-notify-border {
  border: 1px dashed $primary-color;
  padding: 0 10px;
}

.do-notify-negative-border {
  border: 1px dashed red;
  padding: 0 10px;
}

.selected-border {
  border: 1px dashed;
  padding: 0 10px;
}

.dont-notify-color {
  color: $color-gray;
}

.survey-response-main-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.survey-response-card-container {
  width: 100%;
}

.survey-response-likert-panel-header {
  padding-top: 10px;
  height: auto !important;
}

/* Show one page at a time */
.survey-response-pdf-show-singleton {
  position: absolute;
  top: 0;
}

.survey-response-emoji-font-size {
  font-size: 24px;
}

.survey-response-reduced-padding {
  padding: 0 5px;
}

.survey-response-likert-table {
  display: block;
}

.survey-response-likert-accordion {
  display: none;
  .survey-response-likert-panel-header {
    span.mat-content {
      display: block;
    }
  }
}

.survey-response-radiobox {
  width: 100%;

  .survey-response-btn-mat-toggle-group {
    width: 100%;
    overflow-x: auto;
    .survey-response-btn-mat-toggle {
      width: 100%;
      padding: 5px 15px;
      color: #000000;
    }
  }
}

.survey-text-responses-viewport {
  height: 200px;
  width: 100%;
  background-color: $table-odd-row-colour;
}

.survey-text-responses-item {
  height: 18px;
}

.survey-text-responses-alternate {
  background: $table-hover-colour;
}

.survey-response-rating {
  width: 100%;
  .survey-response-rating-icon-button {
    color: orange;
    outline: none;
    margin: 0;
    padding: 0;

    .survey-response-rating-icon {
      font-size: 35px;
      line-height: unset;
      height: auto;
      width: auto;
    }
  }
}

@media screen and (max-width: 1200px) {
  .survey-response-radiobox {
    .survey-response-btn-mat-toggle-group {
      .survey-response-btn-mat-toggle {
        padding: 5px 5px;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .survey-response-radiobox {
    .survey-response-btn-mat-toggle-group {
      .survey-response-btn-mat-toggle {
        padding: 5px 2px;
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .survey-response-radiobox {
    .survey-response-btn-mat-toggle-group {
      .survey-response-btn-mat-toggle {
        padding: 2px 0px;
      }
    }
  }

  .survey-response-likert-table {
    display: none;
  }

  .survey-response-likert-accordion {
    display: block;
  }
}

@media screen and (max-width: 540px) {
  // .survey-response-btn-mat-toggle {
  //   padding: 2px 2px;
  // }

  .survey-response-rating-icon {
    font-size: 25px;
  }
}

@media screen and (max-width: 500px) {
  .survey-response-radiobox {
    width: auto;
    .survey-response-btn-mat-toggle-group {
      .survey-response-btn-mat-toggle {
        padding: 0;
      }
    }
  }

  .survey-response-rating {
    width: auto;
    .survey-response-rating-icon-button {
      width: 20px;
      height: 20px;

      .survey-response-rating-icon {
        font-size: 20px;
        padding: 2px;
      }
    }
    .survey-response-rating-text {
      font-size: 10px;
      padding: 2px;
    }
  }
}

@media screen and (max-width: 450px) {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .survey-response-btn-mat-toggle .mat-button-toggle-label-content {
    padding: 10px 5px !important;
  }
}
