.vertical-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;

  .vertical-radio-button {
    margin: 5px;
  }
}

/* import the Bootstrap */
@import "bootstrap";

/* ------- customize primary button color -------- */

.btn-primary {
  @include button-variant(
    #145993,
    darken(#145993, 7.5%),
    darken(#145993, 10%),
    lighten(#145993, 5%),
    lighten(#145993, 10%),
    darken(#145993, 30%)
  );
}

.btn-outline-primary {
  @include button-outline-variant(
    #145993,
    #8aacc9,
    lighten(#145993, 5%),
    #145993
  );
}

.round-count-only {
  background-color: $off-white-color;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 0.9rem;

  &.margin-right-1 {
    margin-right: 1rem;
  }
}
