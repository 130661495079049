.mat-mdc-menu-item:not(:hover):not(:disabled) {
  background-color: transparent !important;
}

.menu-item {
  .add-icon {
    color: $add-icon;
  }
  .add-icon-disabled {
    color: $add-icon-button;
  }

  .delete-icon {
    color: $delete-icon;
  }
  .delete-icon-disabled {
    color: $delete-icon-button;
  }

  .edit-icon {
    color: $edit-icon;
  }
  .edit-icon-disabled {
    color: $edit-icon-button !important;
  }

  .preview-icon {
    color: $preview-icon;
  }
  .preview-icon-disabled {
    color: $preview-icon-button !important;
  }

  .web-view-icon {
    color: $web-view-icon;
  }
  .web-view-icon-disabled {
    color: $web-view-icon-button;
  }

  .save-icon {
    color: $save-icon;
  }
  .save-icon-disabled {
    color: $save-icon-button;
  }

  .cancel-icon {
    color: $cancel-icon;
  }
  .cancel-icon-disabled {
    color: $cancel-icon-button;
  }

  .copy-icon {
    color: $copy-icon;
  }
  .copy-icon-disabled {
    color: $copy-icon-button !important;
  }

  .status-icon {
    color: $status-active-icon;
  }
  .status-icon-disabled {
    color: $status-active-icon-button;
  }

  .status-icon-inactive {
    color: $status-inactive-icon;
  }
  .status-icon-inactive-disabled {
    color: $status-inactive-icon-button;
  }

  .view-icon {
    color: $view-icon;
  }
  .view-icon-disabled {
    color: $view-icon-button;
  }
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

app-user-settings-menu {
  button.mat-mdc-menu-item,
  & ~ button.mat-mdc-menu-item {
    min-height: 40px;
    min-width: 180px;
  }
}
