.xs-toggle {
  position: absolute;
  top: 1rem;
  font-size: 1rem;
  cursor: pointer;
  z-index: 10;

  &.right {
    right: 1rem;
  }

  &.left {
    left: 1rem;
  }

  .xs-toggle-active {
    color: $brand-color;
  }

  .xs-toggle-inactive {
    color: $brand-color-light;
  }

  .xs-toggle-separator {
    color: $brand-color;
  }

  .xs-close {
    &:hover {
      color: $brand-color !important;
    }
  }
}

.profit-edge-simulator {
  background-color: $dashboard-bgColor;

  .profit-edge-simulator-header {
    position: relative;
    background-color: $white;
    margin: 0.5rem;
    padding: 0.5rem;
    border: 2px solid $brand-color-light;
    border-radius: 5px;

    div.ps-header {
      p {
        margin: 0;
        color: $brand-color;
        &:first-child {
          font-size: 1.5rem;
        }

        &:last-child {
          font-weight: 700;
          text-transform: uppercase;
          font-size: 0.72rem;
          color: $brand-color-light;
        }
      }
    }

    div.ps-header_revenue {
      color: $brand-color;

      padding: 10px;
      border-radius: 3px;
      border: 1px solid var(--brand-color);

      &.no-data {
        color: $color-danger;
        border-color: $color-danger;
      }

      p {
        margin: 0;
      }
    }
  }

  .profit-edge-simulator-body {
    background-color: $white;
    margin: 0.5rem;
    padding: 1rem;
    border: 2px solid $brand-color-light;
    border-radius: 5px;
    position: relative;

    tr {
      .dirty-row {
        background-color: ivory;
      }
    }

    th {
      color: $brand-color;
      font-weight: 500;
      text-align: center;

      .action-impact-input {
        font-size: 1rem;

        .toggle-active {
          color: $brand-color;
          cursor: pointer;
        }

        .toggle-inactive {
          color: $brand-color-light;
          cursor: pointer;
        }

        .toggle-separator {
          color: $brand-color;
        }
      }
    }

    td {
      word-wrap: break-word;
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
      .mat-accent .mat-slider-track-fill,
      .mat-accent .mat-slider-thumb,
      .mat-accent .mat-slider-thumb-label {
        background-color: $brand-color;
        /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
        .mat-slider-thumb-label-text {
          color: white !important;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
      .mat-slider-track-background {
        background-color: $brand-color-light;
      }

      .text-only-column {
        padding-top: 0.8rem;

        &.numeric {
          text-align: center;
        }

        &.calculated {
          color: $brand-color;
        }
      }

      .editable-column {
        padding-top: 0.55rem;
        min-width: 3rem;

        &.group {
          padding-top: 0.33rem;
          min-width: 7rem;
        }
      }

      .percent-text {
        // color: $brand-color !important; got overridden by some crap class implementation, need clean-up later
        font-size: 0.8rem;
        font-weight: 900;
      }

      .shortcut-icon {
        cursor: pointer;
        color: var(--brand-color);
        transition: color 0.25s;

        &:hover {
          color: orangered;
        }
      }
    }

    .profit-edge-simulator-detail-table {
      td {
        font-size: 0.8rem;
        text-align: center;
        vertical-align: middle;
      }

      tr:first-child,
      tr:nth-child(2) {
        border-top: none;
        border-bottom: none;

        td {
          border-top: 1px solid #dee2e6;
        }
      }
    }
  }
}
