/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* 25112020 - Gaurav - Init version: Component HTML to ADD/EDIT/VIEW Holding Org  */
:host,
:root {
  /* Search CSS Colors */
  --btn-bgcolor: #145993;
  --btn-bgcolor-cancel: #dc3545;
  --btn-textColor: #ffffff;
  --tab-invalid-textColor: #dc3545;
  --row-invalid-bgcolor: #dc35454d;
  --disabled-selections: #757575;
  scrollbar-width: thin !important;
}

/* 09032021 - Gaurav - Add some default padding to main-container */
.main-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.card-container {
  width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-actions {
  text-align: right;
}

button {
  &.btn-action, &.btn-cancel {
    &.mat-mdc-raised-button {
      color: var(--btn-textColor);
    }

  }

  &.btn-action {
    &.mat-mdc-raised-button {
      background-color: var(--btn-bgcolor);
    }

  }

  &.btn-cancel {
    &.mat-mdc-raised-button {
      background-color: var(--btn-bgcolor-cancel);
    }

  }

}

.grid-container {
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: auto auto auto;
  padding: 10px;
  padding-top: 15px;
}

mat-form-field {
  width: 100%;
  padding-top: 8px;
}

.tab-invalid,
.invalid {
  color: var(--tab-invalid-textColor);
}

/* Config tab - Starts */
.config-tab {
  padding: 20px;
}

.config-card {
  width: 90%;
  padding: 20px;
}

table {
  width: 100%;
  padding: 10px;
}
th {
  text-align: left;
}

.org-header th {
  text-align: center;
}

/* Careful changing these ::ng-deep settings */
::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle,
::ng-deep
  .mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
::ng-deep
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-persistent-ripple,
::ng-deep .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
::ng-deep
  .mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
::ng-deep
  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--btn-bgcolor);
}

::ng-deep .disable-bgColor.mat-radio-button.mat-accent .mat-radio-inner-circle,
::ng-deep
  .disable-bgColor.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
::ng-deep
  .disable-bgColor.mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-persistent-ripple,
::ng-deep
  .disable-bgColor.mat-radio-button.mat-accent:active
  .mat-radio-persistent-ripple,
::ng-deep
  .disable-bgColor.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
::ng-deep
  .disable-bgColor.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
::ng-deep
  .disable-bgColor.mat-checkbox-indeterminate.mat-accent
  .mat-checkbox-background,
::ng-deep
  .disable-bgColor.mat-checkbox-checked.mat-accent
  .mat-checkbox-background {
  background-color: var(--disabled-selections);
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
::ng-deep
  .mat-mdc-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: var(--btn-bgcolor);
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
::ng-deep
  .disable-bgColor.mat-mdc-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: var(--disabled-selections);
}

.disable-color {
  color: var(--disabled-selections);
}

.row-invalid-bgcolor {
  background-color: var(--row-invalid-bgcolor);
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
mat-checkbox {
  margin-left: 2px;
  margin-right: 11px;
}

.holding-org-selection {
  padding-left: 50px;
}

.member-org-selection {
  padding-left: 90px;
}

.section__2 {
  margin-top: 15px;
}

/* 17022021 - Abhishek - Added padding-right css for column spacing in all the tables */
.mat-mdc-cell {
  padding-right: 20px !important;
}
/* #defaultEmailSender {
  width: 40%;
} */
/* Config tab - Ends */

@media screen and (max-width: 700px) {
  .grid-container {
    grid-template-columns: auto auto;
  }

  th,
  td,
  mat-radio-button,
  mat-check-box {
    font-size: 12px;
  }

  .member-org-selection {
    padding-left: 70px;
  }

  .config-tab {
    padding: 10px;
  }
}

@media screen and (max-width: 600px) {
  .holding-org-selection {
    padding-left: 40px;
  }

  th,
  td,
  mat-radio-button,
  mat-check-box {
    font-size: 11px;
  }

  .config-tab {
    padding: 5px;
  }
}

@media screen and (max-width: 500px) {
  .grid-container {
    grid-template-columns: auto;
  }

  /* 09032021 - Gaurav - Reduced main-container padding on screens less than 500px */
  .main-container {
    padding: 5px;
  }

  .mat-mdc-card-title {
    font-size: 18px;
  }

  .holding-org-selection {
    padding-left: 30px;
  }

  .member-org-selection {
    padding-left: 60px;
  }

  th,
  td,
  mat-radio-button,
  mat-check-box {
    font-size: 10px;
  }

  .config-card {
    padding: 10px;
  }
}

@media screen and (max-width: 450px) {
  .holding-org-selection {
    padding-left: 25px;
  }

  .member-org-selection {
    padding-left: 45px;
  }
}

@media screen and (max-width: 400px) {
  .holding-org-selection {
    padding-left: 20px;
  }

  .member-org-selection {
    padding-left: 35px;
  }
}

@media screen and (max-width: 350px) {
  .holding-org-selection {
    padding-left: 15px;
  }

  .member-org-selection {
    padding-left: 25px;
  }
}
/*
* 28122020 - Abhishek - css media query for thier party library design issue in communication-AI
* 05012021 - Abhishek - Set max-width: 1120px
*/
@media screen and (max-width: 1120px) {
  .unlayer-editor {
    overflow-x: auto !important;
    width: 100%;
  }
}
