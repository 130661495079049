// 21092022 - Gaurav - CA-1499: correct tour element position
.tour-step {
  top: 300px !important;

  .tour-block {
    border-radius: 5px !important;

    .tour-buttons {
      // .skip-button {
      //   display: none;
      // }

      .next-button {
        background-color: $brand-color;
      }

      .back-button {
        color: $brand-color;
      }
    }
  }
}
