//dashboard org configuratoin styles starts
.client-setup-dashboard {
  .filter-header,
  .filter-header2 {
    display: flex;
    flex-direction: row;
    // align-items: $align-center;
  }
  .filter {
    padding-right: 1.5rem;
  }
  .default-form {
    display: flex;
    flex-direction: row;
  }
  .default-field {
    padding-right: 30px;
  }
}
//dashboard org configuratoin styles end

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background,
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: rgba($accent-color, 1) !important;
}
.mat-primary .mat-pseudo-checkbox-checked {
  background: rgba($accent-color, 1) !important;
  &:after {
    color: rgba($theme-bg);
  }
}
mat-ink-bar.mat-ink-bar {
  background-color: $primary-color !important;
  color: rgba($primary-color) !important;
}
/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
mat-slide-toggle {
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  &:not(.mat-checked) .mat-slide-toggle-thumb {
    //background-color: rgba($theme-bg) !important;
    filter: brightness(71%);
  }
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-slide-toggle-bar {
    background-color: rgba($menu-textcolor, 0.2) !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-slide-toggle-content {
    color: rgba($menu-textcolor);
  }
}
.mat-mdc-form-field {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-ripple {
    background-color: transparent;
  }
  .mat-focused {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label {
      color: $primary-color !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-ripple {
      background-color: $primary-color !important;
    }
  }
  &-appearance-outline {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    & .mat-form-field-outline-thick {
      color: $primary-color !important;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    &:hover .mat-form-field-outline-thick {
      color: rgba($menu-textcolor, 0.6) !important;
    }
  }
}

.mat-mdc-form-field {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix input,
      mat-icon {
        color: rgba($menu-textcolor);
        &::placeholder {
          color: rgba($menu-textcolor, 0.4);
        }
      }
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      label.mat-form-field-label {
        color: rgba($menu-textcolor, 0.6);
      }
      svg {
        fill: rgba($menu-textcolor);
      }
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    background-color: rgba($menu-textcolor, 0.5);
  }

  &.mat-focused.mat-primary {
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-arrow {
      color: $primary-color !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline .mat-form-field-ripple {
      transition: all 0.8s;
      background-color: $primary-color !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label mat-label {
      color: $primary-color;
    }
  }

  &:not(.mat-focused.mat-primary):hover {
    &:not(.mat-form-field-disabled) {
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-underline {
        transition: all 0.8s;
        border-bottom: 2px solid rgba($menu-textcolor);
      }
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba($accent-color, 0.54) !important;
}
.disable-bgColor.mat-radio-button.mat-accent .mat-radio-inner-circle,
.disable-bgColor.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.disable-bgColor.mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-persistent-ripple,
.disable-bgColor.mat-radio-button.mat-accent:active
  .mat-radio-persistent-ripple,
.disable-bgColor.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent
  .mat-ripple-element,
.disable-bgColor.mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-accent
  .mat-ripple-element,
.disable-bgColor.mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background,
.disable-bgColor.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background {
  background-color: var(--disabled-selections);
}

.mat-mdc-radio-button {
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: rgba(var(--accent-color), 1);
  }
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  &.mat-radio-disabled {
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label-content {
      color: rgba($menu-textcolor, 0.3);
    }
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-outer-circle {
      border-color: rgba($menu-textcolor, 0.3);
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-outer-circle {
    border-color: rgba($menu-textcolor, 0.6);
  }
}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-mdc-checkbox-frame {
  border-color: rgba($menu-textcolor, 0.6);
}
.mat-pseudo-checkbox {
  color: rgba($menu-textcolor, 0.6);
}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-mdc-checkbox-disabled:not(.mat-mdc-checkbox-checked) .mat-mdc-checkbox-frame {
  border-color: rgba($menu-textcolor, 0.2);
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.disable-bgColor.mat-mdc-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: var(--disabled-selections);
}

.client-setup-container {
  .config-tab {
    padding: 20px;
  }
  table {
    width: 100%;
    padding: 10px;
    th {
      text-align: left;
    }
  }
  .org-header th {
    text-align: center;
  }
  .row-invalid-bgcolor {
    background-color: $row-invalid-bgcolor;
  }

  .mat-mdc-checkbox {
    margin-left: 2px;
    margin-right: 11px;
  }
  .holding-org-selection {
    padding-left: 50px;
  }
  .member-org-selection {
    padding-left: 90px;
  }
  .logo {
    margin: 5px auto;
    width: auto;
    height: 150px;
    object-fit: contain;
  }
}
//load custmer data sytes start
.load-customer-container {
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label {
    padding: 0px !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  mat-card {
    padding: 12px 16px;
  }
}
//load custmer data sytes end
