.modal-xsm {
  width: 300px !important;
  .modal-xsm-body {
    background: #65aaff;
    color: $color-white;
    text-align: $align-center;
    padding: 1rem;
    .count {
      font-size: 3rem;
      line-height: 1;
    }
    .modal-xsm-btn {
      background: $color-white;
      &.cancel-btn {
        background: #a1d9f3;
      }
      &:hover {
        background: #0d70eb;
        color: $color-white;
      }
    }
  }
}

.mat-mdc-dialog-container {
  border-radius: 10px;

  .mat-mdc-dialog-surface {
    padding: 0.5rem;
  }
}

//bottom sheet dialog start
mat-bottom-sheet-container {
  width: 50%;
  margin: auto;

  mat-nav-list {
    &.bottom-sheet-dialog { 
      padding-bottom: $spacer * 2;
      a {
        height: auto !important;
        padding: math.div($spacer, 2) $spacer !important;
        /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
        .mdc-list-item__content {
          padding: 0px !important;
          span.info {
            color: $color-gray;
            white-space: initial !important;
            font-size: 12px !important;
            padding-left: 5px;
          }
        }
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  
}
//bottom sheet dialog end
.custFilterDialog {
  text-align: right;
  display: block !important;
}
//system dialog styles start
.sys-dialog-container {
  .mat-mdc-dialog-container {
    padding: 0px !important;
  }

  .dialog-container {
    padding: 24px;
  }

  .mat-mdc-button {
    min-width: 0px !important;
  }
}
//system dialog styles end

//demo dialog styles start
.demo-dialog-container {
  .rounded{
    .mat-mdc-form-field-hint{
      color: rgba($menu-textcolor, .6)
    }
  }
  .demo-dialog .mat-mdc-dialog-container {
    overflow-y: hidden !important;
  }

  &.main-container.container-fluid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: auto 15px;
    .grid-container {
      display: grid;
      grid-column-gap: 15px;
      grid-template-columns: auto auto;
      padding: 10px;
    }
    .mat-mdc-dialog-content {
      overflow-y: hidden !important;
    }
  }
  .send-btn {
    background-color: $btn-bgcolor;
    color: $btn-textColor;
  }
  .cancel-btn {
    background-color: $btn-bgcolor-cancel;
    color: $btn-textColor;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}
//demo dialog styles end

.dialog-buttons {
  .dialog-button-primary.mat-mdc-button,
  .dialog-button-primary.mat-mdc-icon-button,
  .dialog-button-primary.mat-mdc-outlined-button {
    color: $primary-color;
  }

  .dialog-button-warn.mat-mdc-button,
  .dialog-button-warn.mat-mdc-icon-button,
  .dialog-button-warn.mat-mdc-outlined-button {
    color: $color-danger;
  }
}
.scheduleTotal{
  small{
    span{
      min-width: 22px !important;
      max-height: 22px !important;
      line-height: 22px !important;
      top: -18px !important;
      padding: 0 3px;
      width: auto !important;
    }
  }
}
