// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

:root {
  --theme-bg: 250, 250, 250;
  --menu-textcolor: 0, 0, 0;
  --default-theme: 20, 89, 147;
  --brand-color: #145993;
  --dashboard-bgColor: #e8eef4;
  --brand-color-light: #8aacc9;
  --off-white-color: #f7f6fb;
  --color-active: #32aa53;
  --color-inactive: #dc3545;
  --radio-button-color: #50F3FF;
}

// theme colors
$primary-color: var(--primary-color);
$theme-text-color: var(--text-color);
$theme-type: var(--theme-type);
$menu-textcolor: var(--menu-textcolor);
$theme-bg: var(--theme-bg);
$accent-color: var(--accent-color);
$default-theme: var(--default-theme); // 20, 89, 147;

$dashboard-bgColor: var(--dashboard-bgColor);
$brand-color: var(--brand-color);
$brand-color-light: var(--brand-color-light);
$off-white-color: var(--off-white-color);

$color-white: $theme-text-color;
$color-black: #000;
$color-danger: #ff0000;
$color-gray: rgb(128, 128, 128);
$color-active: var(--color-active);
$color-inactive: var(--color-inactive);
$color-positive: #32aa53;
$color-negative: #ff0000;
$color-positive-checked: #ebf7ee;
$color-negative-checked: #ffe6e6;
$green-color: #24c524;
$red-color: #f44336;

//button colors
//$accent-color: #3dc2ff;
$warn-color: #f44335;
$white: #fff;
$btn-bgcolor: $primary-color;
$btn-critical-bgcolor: #f44335;
$btn-bgcolor-cancel: #dc3545;
$btn-textColor: $theme-text-color;
$btn-hover: rgba(var(--btn-hover-color)); // #104776;
$btn-critical-hover: #c3362a;

//menu list colors
$child-menu-selected-bgcolor: rgba(var(--secondary-color));
$child-menu-selected-color: $theme-text-color;
$child-menu-unselected-bgcolor: #ffffff;
$child-menu-unselected-color: #000000;
$menu-panel-expanded-bgcolor: #ffffff;
$menu-panel-expanded-color: #000000;
$menu-panel-collapsed-bgcolor: $primary-color;
$menu-panel-collapsed-color: $theme-text-color;

//other colors
$side-panel-bgcolor: $primary-color;
$selected-panel-bgcolor: rgba(var(--secondary-color));
$selected-panel-textColor: $theme-text-color;
$unselected-panel-bgcolor: #e91e63; //$primary-color;
$unselected-panel-textColor: $theme-text-color;
$side-panel-rt-border-color: #f3f0f0;
$menu-lock-icon-color: var(--third-color);
$tab-invalid-textColor: #dc3545;
$row-invalid-bgcolor: #dc35454d;
$disabled-selections: #757575;
$field-required-color: #f44335;
$tablerow-bgColor: #dddddd;
$tableroweven-bgColor: #f2f2f2;
$hover-bg-color: #efefef;
$mat-sort-header-sorted: $color-black;
$table-hover-colour: #d4edda80;
$table-odd-row-colour: #d4edda1a;
$table-header-color: #f1f1f1;
$header-bgcolor: $primary-color;
$header-link-color: #ffffff;
$header-collapse-indicator-color: rgba(var(--third-color));
$checked-color: #000000;
$blinking-green: #008000;

//Icons
$add-icon: $primary-color;
$add-icon-button: #1459934d;
$add-icon-button-hover: $primary-color;
$delete-icon: #c45c5c;
$delete-icon-button: #c45c5c4d;
$delete-icon-button-hover: #c45c5c;
$edit-icon: #477b47;
$edit-icon-button: #477b474d;
$edit-icon-button-hover: #477b47;
$disabled-icon-button: #757575;
$preview-icon: #e96e81;
$preview-icon-button: #ec95a24d;
$preview-icon-button-hover: #e96e81;
$web-view-icon: #00bfff;
$web-view-icon-button: #00bfff4d;
$web-view-icon-button-hover: #00bfff;
$all-icons-hover: #ffffff;
$save-icon: #021287;
$save-icon-button: #688bff87;
$save-icon-button-hover: #1c369f;
$cancel-icon: #d7180b;
$cancel-icon-button: #ffdddb;
$cancel-icon-button-hover: #f44335;
$copy-icon: #08b1a8d1;
$copy-icon-button: #1fb7af4d;
$copy-icon-button-hover: #08b1a8d1;
$status-active-icon: #851abd;
$status-active-icon-button: #851abd4d;
$status-active-icon-button-hover: #851abd;
$status-inactive-icon: #c26e06;
$status-inactive-icon-button: #c26e064d;
$status-inactive-icon-button-hover: #c26e06;
$view-icon: #145993;
$view-icon-button: #1459934d;
$view-icon-button-hover: #145a93;
$check-circle-icon: #025d02;
$check-circle-icon-button: #b2d0b2;
$check-circle-icon-button-hover: #006400;
$rating-icon-button: orange;
$color-status-active: #32aa53;
$color-status-inactive: #dc3545;
$download-icon: #281493;
$download-icon-button: #2d14934d;
$download-icon-button-hover: #221084;

//login page variables
$forgot-header-color: #757575;
$forgot-header-hover-color: #ff4081;
$login-bgcolor: #f3f3f3;

//Alignment
$align-center: center;
$spacer: 1rem;
$hundred-percent: 100%;
