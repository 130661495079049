// all related to visibility, change of height/width, transformation, etc.

.visible-hidden {
  visibility: hidden !important;
}

.visible-show {
  visibility: visible !important;
  z-index: 100 !important;
}

.transform-180 {
  transform: rotate(-180deg);
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.flex-1 {
  flex: 1;
}
.float-right {
  float: right !important;
}

//height styles
.max-height-100px {
  max-height: 100px !important;
}
.max-height-50px {
  max-height: 50px !important;
}
.height-025 {
  height: 0.25px !important;
}
.height-1 {
  height: 1px !important;
}

.height-2 {
  height: 2px !important;
}

.height-3 {
  height: 3px !important;
}
.height-5 {
  height: 5px !important;
}
.height-18px {
  height: 18px !important;
}
//width styles
.mx-width-50 {
  max-width: 50px !important;
}
.mx-width-100 {
  max-width: 100px !important;
}
.mx-width-300 {
  max-width: 300px !important;
}
.mx-width-26per {
  max-width: 26% !important;
}
.w-18px {
  width: 18px !important;
}
.w-50px {
  width: 50px !important;
}
.w-70px {
  width: 70px !important;
}
.w-80px {
  width: 80px !important;
}
.w-90px {
  width: 90px !important;
}
.w-100px {
  width: 100px !important;
}
.w-150px {
  width: 150px !important;
}
.w-500px {
  width: 500px !important;
}
.w-auto {
  width: auto !important;
}
.w-20p {
  width: 20% !important;
}

.nob {
  border: none !important;
  background-color: lightgrey !important;
}

//button styles
.outline-none {
  outline: none !important;
}

.smooth-scroll {
  scroll-behavior: smooth !important;
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

.break-wrap-text {
  word-break: break-word;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

// 03102022 - Gaurav - CA-1515: special class on card hover, to be applied when used and along with appOnHover directive
.hover-card {
  &:hover {
    .card-header {
      background-color: aliceblue;
    }
  }
}

@keyframes shake-card {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes blinkin-border {
  50% {
    /* border-color: darkorange; */
    border-color: var(--brand-color-light);
    box-shadow: 0 0 0 0.25rem rgb(30 144 245 / 9%);
  }
}
