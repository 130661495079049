// 07122022 - Gaurav - CA-1606: Redesign Response AI menu

.side-menu-container {
  background-color: $dashboard-bgColor;
  position: fixed;
  margin-top: -2px;
  max-height: 95vh;
  width: 100%;
  // padding-right: 1rem;
  height: 100%;

  a {
    text-decoration: none;
  }

  .side-menu-l-navbar {
    position: fixed;
    margin-top: -2px;
    left: 0;
    height: 100vh;
    background-color: $brand-color;
    padding: 0.5rem 1rem 0 0;
    transition: 0.5s;
    z-index: 500;
    border-right: 1px solid $brand-color-light;

    .side-menu-navbar-div {
      position: relative;
      width: 18px;
      height: auto;

      .side-menu-navbar-caret {
        position: absolute;
        font-size: 1.25rem !important;
        transition: 0.5s;
        cursor: pointer;
        visibility: hidden;

        &:hover {
          color: aqua !important;
          visibility: visible;
        }
      }
    }

    &:hover {
      width: 200px !important;
    }

    &:hover .side-menu-navbar-div .side-menu-navbar-caret {
      visibility: visible !important;
      right: -189px !important;
    }

    .side-menu-nav {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
    }

    .side-menu-nav_link,
    .side-menu-dropdown-btn {
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      column-gap: 0.5rem;
      padding: 0.5rem 0 0.5rem 0.5rem;
      .mat-icon {
        font-size: 1.5rem;
      }
    }

    .side-menu-dropdown-btn {
      position: relative;
      color: $brand-color-light;
      margin-top: 0.25rem;
      transition: 0.3s;
      text-decoration: none;
      border: none;
      background: none;
      width: 100%;
      text-align: left;
      cursor: pointer;
      outline: none;

      &.side-menu-active {
        color: $off-white-color;
      }

      &.side-menu-disabled {
        color: $color-gray;
      }

      &.side-menu-active::before {
        content: "";
        position: absolute;
        left: 0;
        width: 2px;
        height: 32px;
        background-color: $off-white-color;
      }
    }

    .side-menu-dropdown-container {
      transition: opacity 0.5s ease-out;
      opacity: 0;
      height: 0;
      overflow: hidden;

      &.show {
        opacity: 1;
        height: auto;
      }
    }

    .side-menu-nav_link {
      position: relative;
      color: $brand-color-light;
      margin-top: 0.25rem;
      transition: 0.3s;

      &.side-menu-active {
        color: $off-white-color;
      }

      &.side-menu-disabled {
        color: $color-gray;
      }

      &.side-menu-active::before {
        content: "";
        position: absolute;
        left: 0;
        width: 2px;
        height: 32px;
        background-color: $off-white-color;
      }

      &.child-drdw-item {
        padding-left: 0.25rem;
        margin-top: 0;

        .side-menu-nav_icon {
          font-size: 1.25rem !important;
        }

        .side-menu-nav_name {
          &.child-item {
            font-size: 0.8rem;
            padding-left: 1rem;

            width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .side-menu-nav_link:hover {
      color: $off-white-color;

      &.side-menu-disabled {
        color: $color-gray;
      }
    }
  }

  .side-menu-content {
    width: 100%;
    // position: absolute;
    max-height: 100vh;
    padding-bottom: 7rem;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    overflow-y: auto;
  }
}

@media screen and (min-width: 992px) {
  .side-menu-container {
    .side-menu-l-navbar {
      //   top: 60px;
      left: 0;
      padding: 1rem 1rem 0 0;
    }

    // .side-menu-content {

    // }
  }
}
