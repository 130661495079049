.resp-main-container {
  padding: 20px;
  .preview-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: $align-center;
  }
}

// .mat-radio-button.mat-accent .mat-radio-inner-circle,
// .mat-radio-button.mat-accent
//   .mat-radio-ripple
//   .mat-ripple-element:not(.mat-radio-persistent-ripple),
// .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
// .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
// .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
//   background-color: $btn-bgcolor;
// }

.preview-resp-container {
  /* For questionTypes.singleChoiceRadioH OR questionTypes.multiChoiceCheckH - starts */
  .radio-label-vertical-wrapper,
  .checkbox-label-vertical-wrapper {
    padding-bottom: 13px;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }

  .radio-label-vertical-wrapper:before,
  .checkbox-label-vertical-wrapper:before {
    content: " ";
    display: block;
    width: 100%;
    height: 30px;
    background: #efefef;
    position: absolute;
    bottom: 0;
  }

  .radio-label-vertical-wrapper label:not(.radio-label-vertical),
  .checkbox-label-vertical-wrapper label:not(.checkbox-label-vertical) {
    display: block;
    width: $hundred-percent;
  }

  .radio-label-vertical,
  .checkbox-label-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    text-align: $align-center;
  }

  .radio-label-vertical input,
  .checkbox-label-vertical input {
    position: absolute;
    top: 35px;
    left: math.div($hundred-percent, 2);
    margin-left: -6px;
    display: block;
    cursor: pointer;
  }
}

//Drop down selection component styles start
.drop-sel-elements-main {
  padding-top: 15px;
  padding: 10px;
  h3 {
    font-size: 18px;
    &.disable-bgColor {
      color: $disabled-selections;
    }
  }
  .elements-table-container {
    width: $hundred-percent;
    display: flex;
    flex-direction: column;
  }
  .elements-table {
    td,
    th {
      border: 1px solid $tablerow-bgColor;
      padding: 8px;
      text-align: $align-center;
    }
    th {
      padding-top: 12px;
      padding-bottom: 12px;
      background-color: $btn-bgcolor;
      color: $white;
    }
  }
}
//Drop down selection component styles end

//Survey component styles start
.survey-main-container {
  padding: 20px;
  display: flex;
  justify-content: $align-center;
  .survey-card-container {
    width: $hundred-percent;
    .mat-mdc-card {
      background: rgba($theme-bg);
      color: rgba($menu-textcolor);
    }
    .mat-mdc-card-subtitle {
      color: rgba($menu-textcolor);
    }
    .survey-page-add-container {
      width: $hundred-percent;
      display: flex;
      flex-direction: column;
      justify-content: $align-center;
      align-items: $align-center;
      padding: 0.5rem;
      padding-top: 0.1rem;
    }
  }
  mat-expansion-panel {
    background: rgba($menu-textcolor, 0.03);
  }
}
.badge-blue .mat-badge-content,
.mat-step-header[aria-labelledby="page-stepper"] .mat-step-icon-selected,
.mat-step-header[aria-labelledby="page-stepper"] .mat-step-icon-state-done,
.mat-step-header[aria-labelledby="page-stepper"] .mat-step-icon-state-edit {
  background: $btn-bgcolor;
  color: $white;
}

/* 25032021 - Gaurav - Disabled child verticall scrollbar because it ws shown along the main component vertical scroll */
.question-tabs-group .mat-mdc-tab-body-wrapper .mat-tab-body .mat-tab-body-content,
.survey-tabs-group .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content,
.survey-pages-tabs-group
  .mat-tab-body-wrapper
  .mat-tab-body
  .mat-tab-body-content {
  overflow-y: hidden;
}
//Survey component styles end

//single-row-radio-or-checkbox, matrix-radio-or-checkbox, likert-radio-or-checkbox styles start
.elements-main {
  padding: 15px 0 10px 0;
  h3 {
    font-size: 18px;
  }
}
.elements-table-container {
  width: $hundred-percent;
  display: flex;
  flex-direction: column;
  .elements-table {
    td,
    th {
      border: 1px solid $tablerow-bgColor;
      padding: 8px;
      text-align: $align-center;
    }
    th {
      padding-top: 12px;
      padding-bottom: 12px;
      background-color: $btn-bgcolor;
      color: $white;
    }
  }
}
h3.disable-bgColor {
  color: $disabled-selections;
}
//single-row-radio-or-checkbox, matrix-radio-or-checkbox, likert-radio-or-checkbox styles end

//rating styles start
.rating-container {
  .emoji-list {
    border: solid 1px #ccc;
    min-height: 60px;
    background: $white;
    border-radius: 4px;
    overflow: hidden;
    display: block;
  }
  .emoji-box {
    padding: 5px;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: $align-center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: $white;
    font-size: 14px;
  }
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.emoji-box:last-child {
  border: none;
}

.emoji-list.cdk-drop-list-dragging .emoji-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
//rating styles end

//customer list styles start
.customer-list-container {
  .table-top {
    width: $hundred-percent;
    overflow: auto;
    .table-top_row2 {
      display: flex;
      flex-direction: column;
    }
  }

  th.mat-sort-header-sorted {
    color: $mat-sort-header-sorted;
  }
}
//customer list styles end
