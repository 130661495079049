.badge {
  background-color: $primary-color;
  color: $theme-text-color;
}

.mdc-card__actions {
  justify-content: end;
}

.cdk-overlay-container {
  .mat-mdc-menu-panel,
  .mat-mdc-card.mat-focus-indicator {
    background: rgba($theme-bg);
    color: rgba($menu-textcolor);
    button.mat-mdc-menu-item:not([disabled]) {
      color: rgba($menu-textcolor) !important;
    }
    .mat-mdc-menu-item[disabled] {
      color: rgba($menu-textcolor, 0.5);
    }
    .mat-expansion-panel {
      background: rgba($menu-textcolor, 0.08);
      button {
        background: $primary-color;
        color: rgba($menu-textcolor);
      }
    }
  }
}
.light-green-bg {
  background: $table-odd-row-colour;
  .alert-heading {
    color: rgba($menu-textcolor);
  }
}
.mat-mdc-table {
  background: rgba($theme-bg);
  thead {
    th {
      //background: rgba($theme-bg);
      color: rgba($menu-textcolor, 0.6);
      //filter: brightness(0.93);
      border-bottom: none;
      user-select: none;
    }
  }
  .mat-mdc-row {
    background: rgba($theme-bg, 0.95);
    //&:not([matColumnDef="status"])
    td {
      background: rgba($theme-bg, 0.95) !important;
      border-bottom-color: rgba($menu-textcolor, 0.2);
      color: rgba($menu-textcolor);
    }
    &:hover td {
      background: rgba(var(--secondary-color), 0.15) !important;
    }
  }
}
.mat-mdc-paginator {
  background-color: rgba($theme-bg) !important;
  color: rgba($menu-textcolor, 0.6) !important;
}

.cdyNRy .blockbuilder-branding {
  display: none !important;
}

.mat-mdc-card {
  padding: 0.5rem;
}

//Card container styles
.card-container {
  width: $hundred-percent;
  .mat-mdc-card {
    padding: 0.5rem;
    background: $white;
    color: rgba($menu-textcolor);

    .mat-mdc-card-title-group {
      padding: 0.5rem;
    }

    .mat-mdc-card-header {
      color: rgba($menu-textcolor);
      
      .mat-card-header-text {
        margin: 0;
      }
      .mat-mdc-card-subtitle {
        color: rgba($menu-textcolor, 0.5);
      }
    }

    .mat-mdc-card-actions {
      &.mdc-card__actions {
        justify-content: end;
      }
    }
  }
}
.main-container {
  padding: 20px;
  display: flex;
  justify-content: $align-center;
  background-color: $dashboard-bgColor;
}

//campaign/system-admin styles start
.messageInfo,
.load-customer-view,
.adminMessage {
  border: 1px solid #e4e4e4;
  margin: 0;
  margin-top: 10px;
  padding-top: 10px;
  max-width: 100%;
  .col-4,
  .col-md-4,
  .col-sm-12,
  .col-3 {
    padding-bottom: 15px;
    label {
      display: block;
      font-size: 11px;
      color: $color-gray;
      margin: 0;
    }
  }
  .text-center,
  span {
    color: rgba($menu-textcolor);
  }
  .col-12 h4 {
    color: $color-gray;
    margin: 0;
    padding-top: 15px;
  }
  iframe {
    width: 100%;
    border: 6px solid rgba($primary-color, 0.2);
    background: $color-white;
  }
}
.adminMessaageCard {
  h4 {
    margin-bottom: 5px !important;
    color: $color-gray;
  }
  .adminMessage {
    margin: 15px 0;
    margin-top: 15px !important;
  }
  .eventsList > div {
    border: 1px solid #f3f3f3;
  }
  iframe {
    width: 100%;
    border: 6px solid rgba($primary-color, 0.2);
    background: $color-white;
  }
}
//campaign/system-admin styles end

// .mat-table {
//   tbody tr:hover td {
//     background: rgba($primary-color, 1);
//     // td {
//     //   background: none;
//     // }
//   }
// }

//table row expand styles start
.rowExapandTable {
  .expand-td {
    &:hover {
      background-color: none;
    }
    padding-left: 0 !important;
    > div.example-element-detail {
      max-height: 250px;
      overflow: auto;
      pre {
        border-radius: 10px;
        box-shadow: inset 2px 3px 8px #c1c1c1;
        background: #e5f3ff;
        padding: 15px;
        margin: 0;
      }
    }
  }

  tr.example-detail-row {
    height: 0;
  }

  tr.example-element-row:not(.example-expanded-row):hover {
    background: whitesmoke;
  }

  tr.example-element-row:not(.example-expanded-row):active {
    background: $hover-bg-color;
  }

  .example-element-row td {
    border-bottom-width: 0;
  }

  .example-element-detail {
    overflow: hidden;
    display: flex;
  }

  .example-element-diagram {
    min-width: 80px;
    border: 2px solid $color-black;
    padding: 8px;
    font-weight: lighter;
    margin: 8px 0;
    height: 104px;
  }

  .example-element-symbol {
    font-weight: bold;
    font-size: 40px;
    line-height: normal;
  }

  .example-element-description {
    padding: 16px;
    width: $hundred-percent;
    height: $hundred-percent;
    position: relative;
    mat-icon.copy-icon {
      position: absolute;
      right: 40px;
      top: 34px;
      color: $primary-color;
      cursor: pointer;
    }
  }
}
//table row expand styles end

//Mat styles
/* Careful changing these ::ng-deep settings */
.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background {
  background-color: $btn-bgcolor;
}

.disable-bgColor.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent
  .mat-ripple-element,
.disable-bgColor.mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-accent
  .mat-ripple-element,
.disable-bgColor.mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background,
.disable-bgColor.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background {
  background-color: $disabled-selections;
}
/* Roles Drag-Drop - Starts */
// .action-buttons, .mat-stroked-button{
//   margin: 3px 5px !important;
// }
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-mdc-mini-fab .mat-button-wrapper {
  padding: 0px;
}

/*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card-actions {
  text-align: right;
  gap: 5px
}

mat-form-field {
  width: $hundred-percent;
  padding-top: 8px;
  font-size: 14px;
}

.disable-color {
  color: $disabled-selections;
}
.tab-invalid,
.invalid {
  color: $tab-invalid-textColor;
}
th.mat-mdc-header-cell:last-of-type,
tbody td.mat-mdc-cell:last-of-type,
td.mat-mdc-footer-cell:last-of-type {
  padding-right: 0px !important;
}

.grid-container {
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: auto auto auto;
  padding: 10px;
  padding-top: 15px;
}

.chips-grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto;
  padding-top: 15px;
}

.section__2,
.section__3 {
  margin-top: 15px;
}

/* Config tab - Starts */
.config-tab {
  padding: 20px;
}
table {
  width: 100%;
  th.mat-sort-header-sorted {
    color: $mat-sort-header-sorted;
  }
}
.mat-mdc-header-cell {
  font-size: 14px;
  padding-right: 0.5rem !important;
}
.table-container {
  width: $hundred-percent;
  background-color: $white;
  border-radius: 5px;
}

.table-container-table {
  // position: relative; 16122022 - Gaurav - CA-1656: Removed position relaitve prop for table container
  width: $hundred-percent;
  position: relative;
  overflow-x: auto;
  .mat-mdc-table {
    width: $hundred-percent;
    min-width: 350px;
  }

  .active {
    color: $color-status-active !important;
  }

  .inactive {
    color: $color-status-inactive !important;
  }

  .link {
    color: blue !important;
  }

  .emphasize {
    font-weight: bold;
  }

  .resource-remove-text {
    color: red;
    font-weight: bold;
    cursor: pointer;
    margin-top: 27px;
  }
}
.table-top {
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: $align-center;
  box-sizing: border-box;
}

.table-title {
  margin-bottom: 0px !important;
  color: rgba($menu-textcolor);
  padding-left: 12px;

  &.table-sub-title {
    color: $color-gray;
    margin-bottom: 8px !important;
  }
}

// .active {
//   color: $color-status-active !important;
// }

// .inactive {
//   color: $color-status-inactive !important;
// }

// .mat-row {
//   &:nth-child(odd) {
//     background: $table-odd-row-colour;
//   }
//   &:hover {
//     background: $table-hover-colour;
//   }
// }

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-disabled .mat-select-arrow {
  border: none;
}

.cdk-scrollable-list-viewport {
  height: 140px;
  width: 100%;
  background-color: $table-odd-row-colour;
}

.cdk-scrollable-list-item {
  height: 18px;
}

.cdk-scrollable-list-alternate {
  background: $table-hover-colour;
}

.spinner-container,
.spinner-center {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.32);
  z-index: 20000;
  width: 100%;
  height: 100%;
}

.large-font-size {
  font-size: 24px;
}

.reduced-padding {
  padding: 0 5px;
}

.color-primary {
  color: $primary-color;
}

.color-positive {
  color: $color-positive;
}

.color-negative {
  color: $color-negative;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mark-positive.mat-button-toggle-checked {
  background-color: $color-positive-checked;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mark-negative.mat-button-toggle-checked {
  background-color: $color-negative-checked;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mark-neutral.mat-button-toggle-checked {
  font-weight: bold;
}

.background-color-primary {
  background-color: $primary-color;
}

.blinking-element {
  animation: blink 0.5s linear infinite;

  &.green {
    color: $blinking-green;
  }
}

.blinking-text {
  font-size: 14px;
  font-weight: bold;
  animation: blink 0.5s linear infinite;

  &.green {
    color: $blinking-green;
  }
}
//customer details styles start
.customer-container {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled
    .mat-form-field-infix::after {
    color: #000;
  }
}
.customer-right-buttons,
.right-buttons-withDrDw {
  text-align: right;
  button {
    width: auto;
    margin-bottom: 0.5rem;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  mat-form-field .mat-form-field-flex {
    padding: 0;
  }
  .mat-icon {
    color: rgba($menu-textcolor);
  }
}
.right-buttons-withDrDw button {
  width: $hundred-percent;
}
//customer details styles end
//data attributes enum styles start
.enum-headers-align .mat-expansion-panel-header-title,
.enum-headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
  color: rgba($menu-textcolor);
}
.enum-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: $align-center;
}
.enum-headers-align .mat-mdc-form-field + .mat-form-field {
  margin-left: 8px;
}
//data attributes enum styles end
//data attributes  styles start
.always-show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 8px;
}
.always-show-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.drag-row.cdk-drag-disabled {
  /* background: #ccc; */
  cursor: default;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-row:last-child {
  border: none;
}
.drag-table.cdk-drop-list-dragging .drag-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
//data attributes  styles end

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-move {
  cursor: move;
}

.text-color {
  color: $theme-text-color;
}
.align-icon-to-text {
  vertical-align: middle !important;
}
.app-drop-down {
  mat-form-field {
    padding-top: 6px !important;
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    div.mat-form-field-flex {
      padding: 0;
    }
  }
}
table.ellipsisTable {
  th,
  td {
    &.ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 20rem;
      width: 100%;
    }
    &.ellipsEnd {
      text-align: right;
      width: 40px;
    }
  }
}

.mat-mdc-select-panel {
  overflow: hidden !important;
}
mat-form-field.mat-mdc-form-field + .mat-mdc-options {
  overflow: auto;
  max-height: 185px;
  margin-top: -20px;
}
// table thead {
//   background: $table-header-color !important;
// }
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-label,
.label-color {
  color: rgba($menu-textcolor);
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    padding: 0.25em !important;
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
      padding-bottom: 0;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
span.mat-select-value-text,
mat-label,
mat-input-element {
  font-size: 0.9em;
}

/*TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
.mat-paginator-page-size-select {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    padding: 0 0 0.5rem 0.75em !important;
  }
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: 1px solid rgba($menu-textcolor, 0.1);
}
.mat-divider {
  border-top-color: rgba($menu-textcolor, 0.2);
}
.mat-mdc-dialog-container,
.mat-stepper-vertical,
.mat-stepper,
.theme-bg {
  background: rgba($theme-bg);
  color: rgba($menu-textcolor);
  .mat-step,
  .mat-step-text-label,
  .timeVal {
    color: rgba($menu-textcolor);
  }
  .timeZ {
    color: rgba($menu-textcolor, 0.5);
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  &:not(.text-danger, .text-secondary, .disable-color) {
    color: rgba($menu-textcolor);
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-disabled {
  cursor: not-allowed;
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value {
    color: rgba($menu-textcolor, 0.6);
  }
}
.mat-form-field-appearance-legacy .mat-mdc-form-field-hint {
  color: rgba($menu-textcolor, 0.7);
}

.cdk-overlay-pane .mat-mdc-select-panel {
  background: rgba($theme-bg);
  //filter: brightness(148%);
  .mat-mdc-option {
    color: rgba($menu-textcolor) !important;
    &:hover {
      background: rgba($menu-textcolor, 0.02);
    }
    &.mat-mdc-selected:not(.mat-mdc-option-multiple) {
      background: rgba($menu-textcolor, 0.05);
    }
  }
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table thead th {
  color: rgba($menu-textcolor, 0.8);
  font-weight: normal;
}
mat-hint,
.mat-expansion-indicator::after{
  color: rgba($menu-textcolor, 0.8) !important;
}

.download-pdf-bg {
  input.form-control:disabled,
  input.form-control[readonly] {
    color: #000;
  }
  ::placeholder {
    color: rgba($theme-bg 0.5) !important;
  }
}
.survey-response-card-container,
.customer-container {
  input,
  textarea {
    &.form-control:disabled,
    &.form-control[readonly] {
      background-color: rgba($menu-textcolor, 0.06) !important;
      color: rgba($menu-textcolor) !important;
    }
  }
}
mat-drawer-content {
  background-color: rgba($theme-bg) !important;
}
.form-group textarea {
  background: transparent !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-paginator-range-actions .mat-button-disabled svg {
  fill: rgba($menu-textcolor, 0.25) !important;
}
/*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-tree,
mat-card,
.mat-mdc-card > :first-child,
.mat-mdc-card-content > :first-child {
  background: $white !important;
  > .mat-nested-tree-node:not(.background-highlight):not(
      .inactive-record-highlight
    ) {
    // background: rgba($theme-bg); // !important;
    color: rgba($menu-textcolor, 0.7);
    mat-nested-tree-node {
      color: rgba($menu-textcolor, 0.7);
    }
  }
  .background-highlight.inactive-record-highlight {
    background: rgba($menu-textcolor, 0.1) !important;
    mat-nested-tree-node {
      color: rgba($menu-textcolor, 0.1);
    }
  }
  .mat-nested-tree-node:not(.background-highlight) {
    &:hover {
      color: rgba($menu-textcolor);
      background: rgba($menu-textcolor, 0.1) !important;
    }
  }
}
.resizer span {
  color: $primary-color;
}
.roles-dragdrop-list {
  border: 1px solid rgba($theme-bg, 0.9) !important;
  .roles-dragdrop-box {
    background: rgba($theme-bg, 0.95) !important;
    color: rgba($menu-textcolor) !important;
    border: 1px solid rgba($menu-textcolor, 0.2) !important;
  }
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-list-item-disabled,
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba($menu-textcolor, 0.02) !important;
}

// .mat-expansion-panel{
//  // background: rgba($menu-textcolor,.1) !important;
// }
.mat-step-header {
  .mat-step-icon-selected,
  .mat-step-icon-state-edit {
    background: $primary-color;
  }
}
.background-highlight {
  background: aliceblue !important;
  color: rgba($menu-textcolor);
  .mat-nested-tree-node:not(.background-highlight):not(
      .inactive-record-highlight
    ) {
    background: none !important;
    color: rgba($menu-textcolor, 0.7);
  }
}
.inactive-record-highlight {
  color: rgba(
    $menu-textcolor,
    0.4
  ) !important; /* bootstrap alert-dark colour */
  background: none !important;
  mat-nested-tree-node,
  mat-nested-tree-node:hover {
    color: rgba($menu-textcolor, 0.6) !important;
  }
}
.alert-dark {
  background: rgba($menu-textcolor, 0.2) !important;
}
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  textarea:not(.mat-mdc-input-element) {
    background-color: $white !important;
    &:focus {
      background: rgba($theme-bg);
    }
  }
}
.user-org-list {
  color: rgba($menu-textcolor, 0.5);
}
.customer-list-container {
  app-delay-search-field.col-md-6 mat-form-field {
    width: 50%;
  }
}

.default-app-theme,
.purpleAmber,
.indigoPink {
  .mat-mdc-table thead th {
    background: #e8e8e8;
  }
  .alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
  }
}
.pinkBlue,
.purpleGreen {
  .alert-warning {
    background: #444137 !important;
    border-color: #444137 !important;
  }
  .mat-mdc-table thead th {
    background: #3e3e3e;
  }
  input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
    color: black !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-mdc-icon-button.mat-button-disabled.mat-button-disabled,
  .mat-menu-item[disabled] {
    color: rgb(181 176 176 / 26%);
    cursor: not-allowed;
    .mat-icon-no-color {
      color: rgb(181 176 176 / 26%);
    }
  }
}
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-header-pagination {
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-header-pagination-chevron {
    border-color: rgba($menu-textcolor, 0.87);
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  &.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba($menu-textcolor, 0.38);
  }
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-group {
  border: 1px solid rgba($menu-textcolor, 0.1);
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-toggle[role="presentation"] {
    background: rgba($theme-bg);
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    & + .mat-button-toggle {
      border-color: rgba($menu-textcolor, 0.2);
      background: rgba($theme-bg);
    }
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-button {
      color: rgba($menu-textcolor);
      &[aria-pressed="true"] {
        background: rgba($menu-textcolor, 0.1);
        /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        span.mat-button-toggle-label-content {
          font-weight: bold;
        }
      }
      &[aria-pressed="false"] {
        background: rgba($theme-bg);
      }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-focus-overlay {
      opacity: 0 !important;
    }
  }
}
.survey-unlayer-editor {
  .unlayer-editor > iframe:first-child {
    display: none;
  }
}

//PA Events page styles start
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.survey-response-card-container,
.mat-tab-body-content,
app-schedule-parent-info {
  mat-expansion-panel {
    background: rgba($menu-textcolor, 0.03);
    mat-panel-title p {
      margin: 0;
    }
    mat-panel-description {
      color: rgba($menu-textcolor, 0.4);
    }
    .mat-expansion-panel-body,
    table * {
      color: rgba($menu-textcolor);
    }
  }
}
app-schedule-parent-info {
  mat-expansion-panel {
    background: rgba($menu-textcolor, 0.03) !important;
  }
}
//PA Events page styles end
.download-pdf-bg {
  background: white;
  color: black;
  * {
    color: black !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-toggle {
    background: #cdcccc !important;
  }
}
::placeholder {
  color: rgba($menu-textcolor, 0.5) !important;
}
.message-event-close {
  button {
    background: transparent !important;
    mat-icon {
      color: rgba($menu-textcolor) !important;
    }
  }
}

.align-drdw-from-top {
  padding-top: 0.1 rem !important;
}
.position-relative {
  position: relative !important;
}
%outline-m {
  outline: none;
  margin-bottom: 10px;
}
.outline-mb {
  @extend %outline-m;
}
// .btn-o-mb-m {
//   margin: 5px;
//   @extend %outline-m;
// }
.camp-survey-pdf {
  position: absolute;
  margin-top: 150px;
  padding: 14px;
  color: white;
  font-size: 18px;
  text-align: center;
}
.stats-question-container {
  background-color: rgba(87, 45, 151, 0.15);
  box-sizing: border-box;
}
.drop-down-snipper {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.pa-responsedata {
  background-color: rgba(87, 45, 151, 0.15);
  box-sizing: border-box;
}
.pagination-refresh {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 5px;
}

// 08092022 - Gaurav - CA-1473: Show offline and slow network status info in header
.network-status {
  display: inline-block;
  margin-right: 1rem;
  font-size: 0.7rem;
  padding: 0.25rem;
  border-radius: 0.5rem;
  font-weight: bold;
  border: 1px solid;
  visibility: hidden;

  &.offline,
  &.slow {
    visibility: visible;
  }

  &.offline {
    color: red;
    background-color: rgb(255 0 0 / 30%);
  }

  &.slow {
    color: orange;
    background-color: rgb(255 165 0 / 30%);
  }
}

// 13102022 - Gaurav - CA-1537, 1538, 1539
.mat-expansion-panel-header-title {
  align-items: flex-start !important;
}
