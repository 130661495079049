.mat-mdc-button,
.mat-mdc-icon-button {
  background-color: transparent !important;
}

.mat-mdc-button {
  background-color: transparent !important;
}

.mdc-button {
  font-size: 0.85rem !important;
}

// common button styles
button:focus {
  outline: none;
}

.add-icon {
  color: $add-icon;
  vertical-align: middle;
}

.delete-icon {
  color: $delete-icon;
  vertical-align: middle;
}
.edit-icon {
  color: $edit-icon;
  vertical-align: middle;
}
.preview-icon {
  color: $preview-icon;
  vertical-align: middle;
}
.web-view-icon {
  color: $web-view-icon;
  vertical-align: middle;
}
.save-icon {
  color: $save-icon;
  vertical-align: middle;
}
.cancel-icon {
  color: $cancel-icon;
  vertical-align: middle;
}

.info-button {
  color: $white;
  background-color: $status-inactive-icon-button-hover;
}

.btn-action,
.btn-cancel {
  color: $white;
}

.btn-action {
  background-color: $primary-color;
}

.btn-cancel {
  background-color: $btn-bgcolor-cancel;
}
.copy-icon {
  color: $copy-icon;
  vertical-align: middle;
}
.status-icon {
  color: $status-active-icon;
  vertical-align: middle;
}
.status-icon-inactive {
  color: $status-inactive-icon;
  vertical-align: middle;
}
.view-icon {
  color: $view-icon;
  vertical-align: middle;
}
.check-circle-icon {
  color: $check-circle-icon;
  vertical-align: middle;
}
.download-icon {
  color: $download-icon;
  vertical-align: middle;
}
.accordion-action-buttons {
  padding-bottom: 20px;
}
// .right-icon {
//   color: $right-icon;
// }

.action-buttons {
  &.default-button {
    background-color: $btn-bgcolor !important;
    color: $btn-textColor !important;
    &:hover {
      // background-color: $btn-hover;
      filter: brightness(85%);
    }
  }

  &.default-button-critical {
    background-color: $btn-critical-bgcolor !important;
    color: $btn-textColor !important;
    &:hover {
      background-color: $btn-critical-hover !important;
    }
  }

  &.preview-button {
    background-color: darkturquoise !important;
    color: $btn-textColor !important;
    &:hover {
      // background-color: $btn-hover;
      filter: brightness(85%) !important;
    }
  }

  &.default-stroked-button {
    color: $btn-bgcolor !important;
    border-color: $btn-bgcolor !important;
    &:hover {
      // color: $btn-hover;
      // border-color: $btn-hover;
      filter: brightness(85%) !important;
    }
  }

  &.default-stroked-button-critical {
    color: $btn-critical-bgcolor !important;
    border-color: $btn-critical-bgcolor !important;
    &:hover {
      color: $btn-critical-hover !important;
      border-color: $btn-critical-hover !important;
    }
  }

  &.default-flat-button {
    color: $btn-bgcolor !important;
    &:hover {
      // color: $btn-hover;
      // border-color: $btn-hover;
      filter: brightness(85%) !important;
    }
  }

  &.default-flat-button-critical {
    color: $btn-critical-bgcolor !important;
    &:hover {
      color: $btn-critical-hover !important;
    }
  }

  &.default-base-button {
    color: $btn-bgcolor !important;

    &:hover {
      color: $btn-hover !important;
    }
  }

  &.default-base-button-critical {
    color: $btn-critical-bgcolor !important;

    &:hover {
      color: $btn-critical-hover !important;
    }
  }
}

.disabled-icon-button {
  @include action-icon-button-style("disabled");
}

.action-icon-buttons {
  @include action-icon-button-style("enabled");
  &:hover mat-icon {
    color: $white !important;
  }

  &.add-button {
    background-color: $add-icon-button !important;
    &:hover {
      background-color: $add-icon-button-hover !important;
    }
  }

  &.delete-button {
    background-color: $delete-icon-button !important;
    &:hover {
      background-color: $delete-icon-button-hover !important;
    }
  }
  &.edit-button {
    background-color: $edit-icon-button !important;
    &:hover {
      background-color: $edit-icon-button-hover !important;
    }
  }
  &.preview-button {
    background-color: $preview-icon-button !important;
    &:hover {
      background-color: $preview-icon-button-hover !important;
    }
  }
  &.web-view-button {
    background-color: $web-view-icon-button !important;
    &:hover {
      background-color: $web-view-icon-button-hover !important;
    }
  }
  &.save-button {
    background-color: $save-icon-button !important;
    &:hover {
      background-color: $save-icon-button-hover !important;
    }
  }
  &.cancel-button {
    background-color: $cancel-icon-button !important;
    &:hover {
      background-color: $cancel-icon-button-hover !important;
    }
  }
  &.copy-button {
    background-color: $copy-icon-button !important;
    &:hover {
      background-color: $copy-icon-button-hover !important;
    }
  }

  &.status-button {
    background-color: $status-active-icon-button !important;
    &:hover {
      background-color: $status-active-icon-button-hover !important;
    }
  }

  &.status-button-inactive {
    background-color: $status-inactive-icon-button !important;
    &:hover {
      background-color: $status-inactive-icon-button-hover !important;
    }
  }

  &.view-button {
    background-color: $view-icon-button !important;
    &:hover {
      background-color: $view-icon-button-hover !important;
    }
  }

  &.check-circle-icon-button {
    background-color: $check-circle-icon-button !important;
    &:hover {
      background-color: $check-circle-icon-button-hover !important;
    }
  }
  &.download-button {
    background-color: rgba(var(--secondary-color), 0.4) !important;
    mat-icon {
      color: rgba($menu-textcolor) !important;
    }
    &:hover {
      background-color: rgba(var(--secondary-color), 0.2) !important;
    }
  }
  // &.right-button {
  //   background-color: $right-icon-button;
  //   &:hover {
  //     background-color: $right-icon-button-hover;
  //   }
  // }
}

.mat-mdc-mini-fab {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper {
    padding: 0 !important;
  }

  span.mdc-button__label {
    .mat-icon {
      padding-top: 3px;
    }
  }
}

.table-top_row1 {
  display: flex;
  flex-direction: column;
}

.mat-badge-content {
  background: $btn-bgcolor;
  color: $white;
}
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/

.mat-mdc-raised-button {
  // background: rgba($brand-color, 0.1) !important;
  // color: rgba($white, 0.5) !important;

  :has(:disabled) {
    background: rgba($menu-textcolor, 0.1) !important;
    color: rgba($menu-textcolor, 0.5) !important;
  }
}
.mat-mdc-outlined-button {
  border: 1px solid $primary-color;
  color: $primary-color;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  &.mat-button-disabled {
    border: 1px solid rgba($menu-textcolor, 0.5);
    color: rgba($menu-textcolor, 0.5) !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
mat-menu-content {
  button {
    height: 35px;
    font-size: 0.82rem;

    mat-icon {
      vertical-align: middle;
    }
  }
}

.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
  margin: 0 !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: var(--radio-button-color);
  --mdc-radio-selected-hover-icon-color: var(--radio-button-color);
  --mdc-radio-selected-icon-color: var(--radio-button-color);
  --mdc-radio-selected-pressed-icon-color: var(--radio-button-color);
  --mat-mdc-radio-checked-ripple-color: var(--radio-button-color);
}
