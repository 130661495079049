.app-header {
  .menu-list {
    a {
      color: white;
      padding-left: 0.7rem !important;
      padding-right: 0.7rem !important;
      &.active {
        border-radius: 5px;
        background: rgba(#000000, 0.2);
        font-weight: normal !important;
      }
      &#home.pr-4 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-right: 0px !important;
      }
      &.home-caret {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    ul {
      background: $primary-color;
      li {
        &:hover {
          background: rgba(#000000, 0.2) !important;
        }
        a {
          &::after {
            margin-top: 6px;
            float: right;
          }
          &:hover,
          &:focus {
            background: transparent !important;
          }
        }
      }
    }

    // &.disabled {
    //   opacity: 0.3;
    //   pointer-events: none;
    // }
  }
  .navbar-light .navbar-nav .nav-link {
    padding-left: 10px;
    &:not(.disabled) {
      color: white;
    }
    &.disabled {
      color: #ffffff6b;
    }
  }

  .dropdown-item {
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}
