// 27072022 - Gaurav - CA-1364: Add new admin menu structure
// 15092022 - Gaurav - Reduced vertical spacing between menu icons
// 17022023 - Gaurav - Hide scrollbar display

.user-settings-dashboard {
  background-color: $dashboard-bgColor;
  position: fixed;
  margin-top: -2px;
  max-height: 95vh;
  width: 100%;
  padding-right: 1rem;
  height: 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;
  }

  .user-settings-header_toggle_row {
    color: $brand-color;
    // background-color: rgba(232, 238, 244, 0.5);
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.5s;
    width: 100%;
    margin-top: 1rem;

    .module-text {
      color: $brand-color;

      padding: 0.5rem;
      border-radius: 0.2rem;
      font-weight: 500;
      font-size: 1.25rem;
    }
  }

  .user-settings-description {
    color: $brand-color;
    transition: 0.5s;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2rem;
    margin-top: 2rem;

    .main {
      font-weight: 500;
    }
  }

  .user-settings-content {
    width: 100%;
    // height: 100%;
    position: absolute;
    max-height: 100vh;
    padding-bottom: 7rem;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    overflow-y: auto;
    padding-right: 10px;

    .user-type-text {
      color: $off-white-color;
      background-color: $brand-color;
      padding: 0.1rem 0.25rem;
      border-radius: 0.2rem;
      font-size: 0.7rem;
      vertical-align: bottom;
    }

    .user-settings-billing {
      td {
        font-size: 0.7rem;
        vertical-align: middle;
      }

      .edit-payment-methods-pen {
        margin-left: 1rem;
        cursor: pointer;
        font-size: 1rem;
        color: $brand-color;

        &:hover {
          text-shadow: 0 0 0.9rem $brand-color-light;
          transition: 0.25s;
        }

        &:active {
          color: $brand-color-light;
          text-shadow: 0 0 0.1rem $brand-color-light;
        }
      }

      .user-settings-billing-invoices-table {
        td {
          .invoice-status {
            padding: 0.4rem 0.7rem;
            border-radius: 0.3rem;
            font-size: 0.65rem;
            font-weight: 700;

            &.paid {
              color: #008000;
              background-color: rgba(204, 230, 204, 0.2);
            }

            &.draft,
            &.void {
              color: #b79108;
              background-color: rgba(241, 207, 75, 0.2);
            }

            &.open,
            &.uncollectible {
              color: #ff0000;
              background-color: rgba(255, 204, 204, 0.2);
            }
          }

          .download-icon {
            font-size: 1.25rem;
            cursor: pointer;
            vertical-align: middle;

            &.paid {
              color: #008000;
            }

            &.open,
            &.uncollectible {
              color: #9f0606;
            }
          }

          .pay-icon {
            font-size: 1.25rem;
            cursor: pointer;
            vertical-align: middle;

            color: #008000;
          }
        }
      }
    }
  }

  .user-settings-l-navbar {
    position: fixed;
    margin-top: -2px;
    left: 0;
    height: 100vh;
    background-color: $brand-color;
    padding: 0.5rem 1rem 0 0;
    transition: 0.5s;
    z-index: 500;
    border-right: 1px solid $brand-color-light;

    .user-settings-navbar-div {
      position: relative;
      width: 60px;
      height: auto;

      .user-settings-navbar-caret {
        position: absolute;
        font-size: 1.25rem !important;
        transition: 0.5s;
        cursor: pointer;
        visibility: hidden;

        &:hover {
          color: aqua !important;
          visibility: visible;
        }
      }
    }

    &:hover .user-settings-navbar-div .user-settings-navbar-caret {
      visibility: visible !important;
    }

    .user-settings-nav {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
    }

    .user-settings-nav_link,
    .user-settings-dropdown-btn {
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      column-gap: 0.5rem;
      padding: 0.5rem 0 0.5rem 0.75rem;
      .mat-icon {
        font-size: 1.5rem;
      }
    }

    .user-settings-dropdown-btn {
      position: relative;
      color: $brand-color-light;
      margin-top: 0.25rem;
      transition: 0.3s;
      text-decoration: none;
      border: none;
      background: none;
      width: 100%;
      text-align: left;
      cursor: pointer;
      outline: none;

      &.user-settings-active {
        color: $off-white-color;
      }

      &.user-settings-disabled {
        color: $color-gray;
      }

      &.user-settings-active::before {
        content: "";
        position: absolute;
        left: 0;
        width: 2px;
        height: 32px;
        background-color: $off-white-color;
      }
    }

    .user-settings-dropdown-container {
      transition: opacity 0.5s ease-out;
      opacity: 0;
      height: 0;
      overflow: hidden;

      &.show {
        opacity: 1;
        height: auto;
      }
    }

    .user-settings-nav_link {
      position: relative;
      color: $brand-color-light;
      margin-top: 0.25rem;
      transition: 0.3s;

      &.user-settings-active {
        color: $off-white-color;
      }

      &.user-settings-disabled {
        color: $color-gray;
      }

      &.user-settings-active::before {
        content: "";
        position: absolute;
        left: 0;
        width: 2px;
        height: 32px;
        background-color: $off-white-color;
      }

      &.child-drdw-item {
        padding-left: 1.5rem;
        margin-top: 0;

        .user-settings-nav_icon {
          font-size: 1.25rem !important;
        }

        .user-settings-nav_name {
          &.child-item {
            font-size: 0.8rem;

            width: 109px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .user-settings-nav_link:hover {
      color: $off-white-color;

      &.user-settings-disabled {
        color: $color-gray;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .user-settings-dashboard {
    .user-settings-l-navbar {
      //   top: 60px;
      left: 0;
      padding: 1rem 1rem 0 0;
    }

    .user-settings-header_toggle_row {
      .module-text {
        font-size: 1.5rem;
      }
    }

    .user-settings-content {
      .user-settings-billing {
        td {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.ng-bootstrap-tabs {
  .nav-link {
    color: #000000;

    &.active {
      color: $brand-color;
      font-weight: bold;
      font-size: 1rem;
    }

    &.disabled {
      color: #999999;
    }

    .nav-link-icon {
      font-size: 1.25rem;
      padding-top: 1px;

      &.active {
        color: $brand-color;
        font-size: 1.5rem;
        padding-top: 0;
      }
    }
  }
}
