//data-attributes styles start
.system-admin-container{
  &.cdk-drag-disabled {
    background: #ccc;
    cursor: default;
  }
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  .cdk-drag-placeholder {
    opacity: 0;
  }
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .drag-row:last-child {
    border: none;
  }

  .drag-table.cdk-drop-list-dragging .drag-row:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}
//data-attributes styles end
/* 22042021 - Abhishek - JIRA-CA-372: Add module subscriptions to orgs > Update UI */
.ps-25 {
  padding-left: 25px;
}

/* 30042021 - Abhishek - JIRA-CA-412: Add Holding Org Dropdown for Member Org config screen. */
.display-flex {
  display: flex;
  flex-direction: row;
}

.me-25 {
  margin-right: 25px;
}
