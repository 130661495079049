@keyframes blink {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}
