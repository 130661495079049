.brand-color {
  color: $brand-color !important;

  &.light {
    color: $brand-color-light !important;
  }
}

.default-color {
  color: $green-color !important; //#24c524
}

.green-color {
  color: $green-color !important; //#24c524
}

.red-color {
  color: $red-color !important; //#f44336
}

.danger-red-color {
  color: $color-danger !important; //#ff0000
}

.active-color {
  color: $color-active !important; //#32aa53
}
.light-gray-bg {
  background-color: #d4edda1a !important;
}
.gray-color {
  color: gray !important;
}

.light-gray-color {
  color: lightgray !important;
}

.gray-bg {
  background: gray !important;
}

.white-color {
  color: white !important;
}

.white-bg {
  background: $color-white !important;
}

.brand-bg {
  color: white !important;
  background-color: $brand-color !important;
}

.orange-color {
  color: orange !important;
}

.transparent-black-color {
  color: rgba(0, 0, 0, 0.3) !important;
}

.light-yellow-color {
  color: #ebeb3e;
}

.field-required {
  color: $field-required-color !important;
}

.turquoise-color {
  color: darkturquoise !important;
}

.turquoise-bg {
  background-color: darkturquoise !important;
}

.danger-bg {
  background-color: $color-danger !important;
}

.transparent-bg {
  background-color: transparent;
}
