.main-header {
  background-color: $header-bgcolor;
  padding-left: 0 !important;
  .menu-title {
    display: flex;
    flex-direction: row;
    justify-content: $align-center;
    align-content: $align-center;
  }
  .drawer-top {
    background-color: $white;
    width: 249px;
    border-radius: 0;
    padding: 0;
    left: 0;
    .logo {
      text-align: $align-center;
      position: relative;
      img {
        margin: 5px auto;
        width: auto;
        height: 50px;
        object-fit: contain;
      }
      .menu-hamburger-icon {
        position: absolute;
        right: 2px;
        top: 18px;
        font-size: 24px;
        cursor: pointer;
        @include theme-text-color("theme", $menu-lock-icon-color, 1);
      }
    }
  }
  mat-icon {
    color: $header-collapse-indicator-color;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: var(--header-link-color);
  }

  a:hover {
    cursor: pointer;
  }

  ul {
    display: flex;
  }
  /* mat-toolbar uses flex by default, make sure the in-between spacer class component
  occupies the remaining space, between 'Franks User Admin' and other RTL links  */
  .spacer {
    flex: 1 1 auto;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.  */
/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.  */
.mat-menu {
  .mat-mdc-menu-item .mat-icon {
    margin-right: 0px;
    font-size: 18px;
    &.disable-color {
      color: rgba(0, 0, 0, 0.38);
    }
  }
  // mat-icon {
  //   color: rgba($menu-textcolor) !important;
  // }
}
//Header styles start
.header {
  .menu-title {
    .mat-icon {
      color: $color-white;
    }
  }
  .menu-right {
    &:hover,
    &.active {
      .mat-icon {
        color: $color-white;
      }
    }
    .mat-icon {
      color: $color-white;
    }
  }

  .user-name {
    // color: $color-white;
    color: $primary-color;
    font-weight: 100;
    font-size: 1rem;

    &.offline {
      color: $table-hover-colour;
      font-weight: 100;
      font-size: 1rem;

      mat-icon {
        font-size: 1.4rem;
        color: $table-hover-colour;
      }
    }
  }

  .org-name {
    color: rgba($accent-color, 1);
    font-weight: 100;
    font-size: 0.75rem;

    &.offline {
      color: $table-hover-colour;
      font-weight: 100;
      font-size: 0.75rem;
    }
  }

  .mat-mdc-icon-button {
    width: 30px;
    height: 30px;
    line-height: -1px;
    mat-icon {
      font-size: 1.2rem;
      line-height: 15px;
    }
  }
}
//Header styles end

.org-selector-logo {
  text-align: center;
  position: relative;

  img {
    margin: 5px auto;
    width: auto;
    height: 50px;
    object-fit: contain;
  }
}
.theme-menu {
  .radio-group {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    .radio-button {
      &:hover {
        background: rgba($menu-textcolor, 0.02);
      }
      label {
        margin: 0;
        padding: 10px;
      }
    }
  }
}
