// 18082022 - Gaurav - CA-1434: Create reusable bootstrap input control
// 27102022 - Gaurav - CA-1565: Upgraded form field to support checkbox and password toggle

.form-control-row {
  --bs-gutter-x: 0.5rem !important;
  margin: 1px;
  width: 100%;

  .form-control-alignment {
    align-self: center;

    &.switch {
      padding-top: 1.8rem;
    }

    &.select {
      padding-top: 0.35rem;
    }
  }
}

.form-control-container {
  min-width: 90px;

  .form-label {
    font-size: 0.7rem !important;
    margin-bottom: 0.25rem !important;
  }

  &.no-padding {
    padding: 0 !important;
  }

  input.form-check-input {
    cursor: pointer;

    &:checked {
      background-color: $brand-color;
      border-color: $brand-color;
    }
  }
}

.form-control-hint {
  font-size: 0.7rem;
}

.form-control-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.7rem;
  color: #dc3545;
}

.form-control-switch-container {
  // padding-top: 9px;

  .form-control-switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      border-radius: 25px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 19px;
      width: 19px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      border-radius: 50%;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #145993;
    }

    input:hover:not([disabled]) + .slider {
      background-color: #8aacc9;
    }

    input:disabled + .slider {
      cursor: default;
    }

    input:disabled:checked + .slider {
      background-color: #8aacc9;
      cursor: default;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
  }

  .form-control-switch-label {
    padding: 0 5px;
  }

  .form-control-switch-hint {
    font-size: 0.8rem;
    padding-left: 0.2rem;
  }
}

input.form-control:focus,
input.form-check-input:focus,
textarea.form-control:focus {
  border-color: var(--brand-color-light) !important;
  box-shadow: 0 0 0 0.25rem rgb(30 144 245 / 9%) !important;
}
