@media screen and (min-width: 1400px) {
  .form-control-row {
    --bs-gutter-x: 1.5rem !important;
  }
}

@media screen and (max-width: 1300px) {
  .table-top {
    flex-direction: column;
    align-items: flex-start;
  }
  .table-top_row1 {
    flex-direction: inherit;
  }
  .table-top_row2 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .custom-margin {
    margin-right: 15px;
  }
}

@media screen and (max-width: 1100px) {
  .dashboard-container {
    mat-expansion-panel-header {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media screen and (min-width: 901px) {
  .login-container {
    width: 600px;
  }
}
@media (min-width: 992px) {
  .mobile-theme {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .login-container {
    width: 50%;
  }
  th,
  td,
  mat-radio-button,
  mat-check-box {
    font-size: 12px;
  }

  .dashboard-container {
    mat-expansion-panel-header {
      padding-left: 10px;
      padding-right: 10px;
    }

    .menu-lock-icon {
      display: none;
    }
  }

  .app-grand-container {
    .app-header {
      app-network-info {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .dashboard-container {
    mat-panel-title mat-icon {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 790px) {
  .customer-right-buttons {
    button {
      width: $hundred-percent;
    }
  }
  .table-top_row2 {
    flex-direction: column;
  }
  .custom-margin {
    margin-right: 0px;
  }
}

@media screen and (max-width: 770px) {
  .flex-style {
    flex-direction: column;
  }
  .forgot-btn {
    width: 100%;
  }
  .client-setup-dashboard .customer-right-buttons {
    margin: 0;
    padding: 0;
  }
}
@media screen and (max-width: 700px) {
  .login-container {
    width: 60%;
  }
  .grid-container {
    grid-template-columns: auto auto;
  }

  th,
  td,
  mat-radio-button,
  mat-check-box {
    font-size: 12px;
  }
  .member-org-selection {
    padding-left: 70px;
  }
  .config-tab {
    padding: 10px;
  }

  .client-setup-dashboard {
    .table-top_row2 {
      width: $hundred-percent;
    }
    .filter-header2 {
      flex-direction: column;
    }
    .filter {
      padding-right: 0px;
    }
  }
}

@media screen and (max-width: 600px) {
  .login-container {
    width: 70%;
  }
  th,
  td,
  mat-radio-button,
  mat-check-box {
    font-size: 11px;
  }

  .elements-main {
    padding: 5px;
    h3 {
      font-size: 16px;
    }
  }
  .holding-org-selection {
    padding-left: 40px;
  }
  .config-tab {
    padding: 5px;
  }
  // .app-tree-div{
  //   display: block;
  //   .app-tree-container{
  //     width: 100%;
  //     .resizer{
  //       display: none;
  //     }
  //   }
  // }

  .form-control-container {
    .form-label {
      font-size: 0.6rem !important;
      margin-bottom: 0.2rem !important;
    }

    .form-control-hint {
      font-size: 0.6rem;
    }
  }

  .form-control-switch-container {
    .form-control-switch-hint {
      font-size: 0.7rem;
    }
  }
}
@media screen and (max-width: 550px) {
  .main-container {
    padding: 10px;
  }
}

@media screen and (max-width: 500px) {
  .login-container {
    width: 80%;
  }
  .grid-container {
    grid-template-columns: auto;
  }

  .mat-mdc-card-title {
    font-size: 18px;
  }
  th,
  td,
  mat-radio-button,
  mat-check-box {
    font-size: 10px;
  }

  .dashboard-container {
    mat-panel-title {
      font-size: 13px;
    }

    .mat-mdc-list-item {
      font-size: 13px;
    }

    mat-panel-title mat-icon {
      font-size: 20px;
    }
  }
  .app-tree-div.ng-star-inserted {
    display: block !important;
    .app-tree-container.tree-container {
      width: 100%;
      height: 150px;
      border: 1px solid #dfdfdf;
      margin: 15px 0;
      max-height: 150px !important;
      min-height: 150px !important;
      mat-tree {
        width: 100%;
      }
    }
    .resizer {
      display: none;
    }
    .app-tree-detail-container {
      padding: 0 !important;
      .main-container {
        padding: 5px 2px !important;
      }
    }
    .mat-focus-indicator {
      padding-left: 0px;
    }
  }

  .client-setup-dashboard {
    .filter-header {
      flex-direction: column;
    }
    .filter {
      padding-right: 0px;
    }
  }
}

@media screen and (max-width: 450px) {
  .client-setup-dashboard {
    .default-form {
      flex-direction: column;
    }
    .default-field {
      padding-right: 0px;
    }
  }
  .holding-org-selection {
    padding-left: 25px;
  }

  .member-org-selection {
    padding-left: 45px;
  }
  .action-icon-buttons {
    width: 25px;
    height: 25px;
    mat-icon {
      font-size: 14px;
      width: 18px;
      height: 18px;
    }
  }
  .mat-mdc-form-field {
    font-size: 12px;
    width: $hundred-percent;
  }

  .mat-mdc-header-cell {
    font-size: 12px;
  }

  .mat-mdc-cell {
    font-size: 12px;
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 10px;
  }

  .table-top {
    flex-direction: column;
  }

  .table-top_row1,
  .table-top_row2,
  .table-add-btn {
    width: $hundred-percent;
    text-align: $align-center;
  }
  /* 05012021 - Abhishek - Set css to set header design */
  .col {
    padding: 0px;
  }
}

@media screen and (max-width: 400px) {
  .login-container {
    width: 90%;
  }
  .mat-mdc-card {
    padding: 10px;
  }

  .elements-main {
    h3 {
      font-size: 13px;
    }
  }
  .holding-org-selection {
    padding-left: 20px;
  }

  .member-org-selection {
    padding-left: 35px;
  }
  .hide-small-screen {
    display: none;
  }
}

@media screen and (max-width: 350px) {
  .holding-org-selection {
    padding-left: 15px;
  }

  .member-org-selection {
    padding-left: 25px;
  }
}

// MEDIA HEIGHT
@media all and (max-height: 1400px) {
  .chat-session-virtual-scroll {
    height: 950px;
  }
}

@media all and (max-height: 1200px) {
  .chat-session-virtual-scroll {
    height: 850px;
  }
}

@media all and (max-height: 1180px) {
  .chat-session-virtual-scroll {
    height: 750px;
  }
}

@media all and (max-height: 1024px) {
  .chat-session-virtual-scroll {
    height: 660px;
  }
}

@media all and (max-height: 900px) {
  .chat-session-virtual-scroll {
    height: 550px;
  }
}

@media all and (max-height: 850px) {
  .chat-session-virtual-scroll {
    height: 500px;
  }
}

@media all and (max-height: 750px) {
  .chat-session-virtual-scroll {
    height: 400px;
  }
}

@media all and (max-height: 650px) {
  .chat-session-virtual-scroll {
    height: 300px;
  }
}

@media all and (max-height: 500px) {
  .chat-session-virtual-scroll {
    height: 150px;
  }
}
