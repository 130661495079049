// 21092022 - Gaurav - CA-1501: Prototype for 'onboarding steps'
$onboarding-success-txt-color: #004d00;
$onboarding-success-txt-color-dark: #001a00;
$onboarding-success-bg-color: #009900;
$onboarding-success-bg-color-light: #f3fdf3;
$onboarding-success-border-color: #e6ffe6;

.onboarding-grand-container {
  background: rgba($color: #000000, $alpha: 1);
  width: $hundred-percent;
  height: $hundred-percent;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .onboarding-container {
    width: $hundred-percent;
    position: relative;
    background-color: #f3f8fd;
    border-radius: 5px;

    &.onboarding-container--success {
      background-color: $onboarding-success-bg-color-light;
    }

    .onboarding-container__divider {
      margin: 0.5rem auto;
    }

    .onboarding-container__header,
    .onboarding-container__header--small {
      width: $hundred-percent;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .onboarding-container__header__title {
        font-weight: bold;
        color: $brand-color;

        &.title--onboarded {
          color: $onboarding-success-txt-color;
        }
      }

      .onboarding-container__header_logo {
        img {
          object-fit: contain;
        }
      }
    }

    .onboarding-container__header {
      padding: 1rem 0.5rem;

      .onboarding-container__header__title {
        font-size: 2rem;
      }

      .onboarding-container__header_logo {
        img {
          width: math.div($hundred-percent, 2) + 10%;
          padding: 21px 0;
        }
      }
    }

    .onboarding-container__header--small {
      padding: 0.5rem 0.25rem;

      .onboarding-container__header__title {
        font-size: 1.25rem;
      }

      .onboarding-container__header_logo {
        img {
          width: math.div(40%, 2) + 10%;
        }
      }
    }

    .onboarding-container__progress {
      border: 2px solid;
      padding: 0.5rem;
      border-radius: 5px;
      margin: 0.5rem 0.25rem;
      border-color: $brand-color-light;
      transition: border-color 1s;

      &.progress--completed {
        border-color: $onboarding-success-txt-color;
      }

      .onboarding-container__progress__title {
        font-weight: bold !important;
        color: $brand-color !important;
        transition: color 1s;

        &.progress--completed {
          color: $onboarding-success-bg-color !important;
        }
      }

      .onboarding-container__progress__bar__container {
        width: 100%;
        border: 1px solid;
        background-color: #f3f8fd;
        border-color: $brand-color-light;
        transition: border-color 1s;

        &.progress--step-completed {
          border-color: $brand-color;
        }

        &.progress--completed {
          border-color: $onboarding-success-txt-color;
        }

        .onboarding-container__progress__bar {
          background-color: $brand-color-light;
          transition: background-color 1s;

          &.progress--step-completed {
            background-color: $brand-color;
          }

          &.progress--completed {
            background-color: $onboarding-success-txt-color;
          }
        }
      }
    }

    .onboarding-container__footer {
      width: $hundred-percent;
      padding: 1rem 0.5rem;
      display: inline-flex;
      flex-direction: row-reverse;

      .onboarding-container__footer__button {
        color: $white;
        background-color: $brand-color;
        border-color: $brand-color-light;

        &.button--success {
          background-color: $onboarding-success-bg-color;
          border-color: $onboarding-success-border-color;
        }
      }
    }

    .onboarding-container__welcome,
    .onboarding-container__completed {
      width: $hundred-percent;
      padding: 2rem;
    }
  }
}

.onboarding-grand-container::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: var(--onboarding-image-opacity);
  background-image: url("../../img/login-bg/8.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

@media screen and (max-width: 575px) {
  .onboarding-grand-container {
    .onboarding-container {
      width: 90%;

      .onboarding-container__header {
        .onboarding-container__header__title {
          font-size: 1.55rem;
        }
      }
    }
  }
}
