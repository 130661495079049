.home-container {
  height: $hundred-percent;
  display: flex;
  flex-direction: column;
  align-items: $align-center;
  .home-top {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: $align-center;
    justify-content: space-around;
    text-align: $align-center;
  }
  .home-username h1 {
    color: $header-bgcolor;
    font-weight: 300;
  }
  .home-logo img {
    width: auto;
    height: 77px;
    object-fit: contain;
  }
  .home-info{
    color: rgba($menu-textcolor, .6);
  }
  .home-footer {
    hr{
      margin-top: 0;
    }
    width: $hundred-percent;
    margin: 15px 0;
    margin-top:0;
    .footer-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .footer-links {
        padding-left: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        a {
          cursor: pointer;
          color: $header-bgcolor;
          &:hover {
            color: #ff4081;
          }
        }
      }
      .footer-copyright {
        padding-right: 15px;
        color: rgba($menu-textcolor);
      }
    }
  }
  iframe {
    width: 100%;
    height: 100%;
    //max-width: 1440px;
  }
}

/* MEDIA QUEIRES */
@media screen and (max-width: 1200px) {
  .footer-links {
    flex-basis: 20%;
  }
}

@media screen and (max-width: 900px) {
  .footer-links {
    flex-basis: 25%;
  }
}

@media screen and (max-width: 700px) {
  .footer-links {
    flex-basis: 30%;
  }
}

@media screen and (max-width: 600px) {
  .footer-links {
    flex-basis: 40%;
  }
}

@media screen and (max-width: 450px) {
  .footer-links {
    justify-content: $align-center;
    align-items: $align-center;
  }

  .footer-links a {
    padding: 10px;
  }

  .footer-content {
    flex-direction: column;
    justify-content: $align-center;
    align-items: $align-center;
  }
}
