.system-dialog-container, .campaign-dialog-container{
  .criteria-table{
    thead{
      background: rgba($menu-textcolor, .1);
      th{
        width: 80px;
        &:first-child{
          width: 150px;
        }
      }
    }
    tr{
      td{
        span{
          display: block;
          color:rgba($menu-textcolor, .4) !important;
          font-size: .8rem;
        }
      }
    }
  }
  //max-width: 500px;
  .camp-details{
    padding: 15px;
    background: rgba($theme-bg);
    padding-top: 0;
    margin-top: 15px;
    border-radius: 5px;
    //box-shadow: inset 2px 3px 8px #c1c1c1;
    border: 1px solid;
    .thumnail{
      background: rgba($theme-bg);
      filter: brightness(130%);
    }
  }
  .dialog-body{
    .thumnail{

      text-align: center;
      padding: 6px;
      border-radius: 5px;
      //box-shadow: 0px 2px 6px #cccccc;
      span.media-icon-total{
        display: inline-block;
        color: white;
        padding: 10px;
        border-radius: 5px 0 0px 5px;
        & + div{
          padding-top: 4px;
        }
        mat-icon{
          font-size: 20px;
          text-align: center;
        }
      }
      &.email-icon  {
        background: #cedff9;
        & > span {
          background: #3568b7;
        }
        .count{
          color: #3568b7;
        }
      }
      &.sms-icon{
        background: #cef9d5;
        & > span {
          background: #35b739;
        }
        .count{
          color: #35b739;
        }
      }
      &.whatsApp-icon{
        background: #f9eece;
        & > span {
          background: #b78935;
        }
        .count{
          color: #b78935;
        }
      }
      span.media-icon{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        color: black;
        mat-icon{
          margin-top: 13px;
          width: auto;
        }
        &.email-icon{
          background: #8cb4f1;
          mat-icon{
            color: #3568b7;
          }
        }
        &.email-invalid-icon{
          background: #eda387;
          mat-icon{
            color: #ab3131;
          }
        }
        &.sms-icon{
          background: #93f1a3;
          mat-icon{
            color: #35b739;
          }
        }
        &.sms-invalid-icon{
          background: #eda387;
          mat-icon{
            color: #ab3131;
          }
        }
        &.whatsApp-icon{
          background: #f9eece;
          mat-icon{
            color: #b78935;
          }
        }
      }
      p, div{
        margin: 0;
        font-size: 12px;
        text-align: left;
        width: 90px;
        span.count{
          font-size: 20px;
        }
      }
      span.text-view{
        font-size: 12px;
        display: block;
        color: gray;
      }
      // &:hover{
      //   border: 1px solid rgba($primary-color, 0.5);
      //   box-shadow: 0px 2px 3px #dad7d7;
      // }
    }
  }
}
