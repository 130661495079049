// all related to alignment, margins, padding, etc.
//alignment styles
.align-drdw-from-top {
  padding-top: 0.1 rem !important;
}
.vertical-align-super {
  vertical-align: super !important;
}
.vertical-align-textTop {
  vertical-align: text-top !important;
}
.vertical-align-bottom {
  vertical-align: bottom !important;
}

.align-right {
  margin-left: auto !important;
  margin-right: 0 !important;
}

.align-left {
  margin-right: auto !important;
  margin-left: 0 !important;
}
.schedule-mat-icon {
  vertical-align: middle;
  font-size: 18px;
}

//margin styles
.mr-5 {
  margin-right: 5px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-2-5px {
  margin-top: 2.5px !important;
}
.mgb-5 {
  margin-bottom: 5px !important;
}
.mrg-2 {
  margin-right: 2px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-21px {
  margin-top: 21px !important;
}
.mt-20px {
  margin-top: 20px !important;
}
.mt-12px {
  margin-top: 12px !important;
}
.mt-5px {
  margin-top: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml--10 {
  margin-left: -10px !important;
}
.ml-11px {
  margin-left: 11px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.m-10px-5px {
  margin: 10px 5px 5px !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

//padding styles
.p-14px {
  padding: 14px !important;
}
.pt-inherit {
  padding-top: inherit !important;
}
.pt-1px {
  padding-top: 1px !important;
}
.pt-5px {
  padding-top: 5px !important;
}
.pt-05rem {
  padding-top: 0.5rem !important;
}
.pl-5px {
  padding-left: 5px !important;
}
.pl-10px {
  padding-left: 10px !important;
}
.pr-15px {
  padding-right: 15px !important;
}
.pr-5px {
  padding-right: 5px !important;
}
.pt-01rem {
  padding-top: 0.1rem !important;
}
.pt-9px {
  padding-top: 9px !important;
}
.p-5px-15px {
  padding: 5px 15px !important;
}
.p-0-16 {
  padding: 0px 16px !important;
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  mat-card-title {
    font-size: 18px !important;
    &.upload-title {
      margin-top: 4px !important;
      margin: 0px !important;
    }
    &.download-title {
      margin-bottom: 0px !important;
    }
  }
}

//font-size styles
.font-size-075rem {
  font-size: 0.75rem !important;
}
.font-size-150p {
  font-size: 1.5rem !important;
}
.font-size-8 {
  font-size: 8px !important;
}
.font-size-9 {
  font-size: 9px !important;
}
.font-size-11 {
  font-size: 11px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-08rem {
  font-size: 0.8rem !important;
}
.font-size-1_6rem {
  font-size: 1.6rem !important;
}
.font-size-inherit {
  font-size: inherit !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.font-style-italic {
  font-style: italic !important;
}
//background styles
.bg-light-gray {
  background-color: #fff0e6 !important;
}
//text styles
.text-orange {
  color: orange !important;
}
.text-black {
  color: #000000 !important;
}
.line-height-18px {
  line-height: 18px !important;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.div-bg-color {
  background-color: $dashboard-bgColor;
}

.mat-mdc-tooltip {
  white-space: pre-line !important;
  background: rgba(20, 89, 147, 0.9) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
.mat-tooltip-standard-bg {
  background: rgba(0, 21, 35, 0.9) !important;
  // default bg colour is #727272
}

.br-5 {
  border-radius: 5px;
}

// 31082022 - Gaurav - CA-1459: Added animated table arrow class that is set visible by ShowTableVscrollDirective
.thead-arrow {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  border-top: none !important;
  padding: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  visibility: hidden;
  transition: visibility 0.25s;

  animation-name: theadFontSize;
  animation-duration: 2s;
  animation-iteration-count: 1;

  &.left-right {
    font-size: 1.25rem;
  }
}

@keyframes theadFontSize {
  0% {
    font-size: 1rem;
  }
  50% {
    font-size: 1.5rem;
  }
  100% {
    font-size: 1rem;
  }
}

.mdc-card__actions {
  flex-wrap: wrap;
}
