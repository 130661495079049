// 12012021 - Gaurav - Organized common components in diff scss files and added response type CSS
// 10062022 - Gaurav - New arc rotating spinner

@use "sass:math";
@import "../../../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss";

//Abstracts
@import "./abstracts/variables";
@import "./abstracts/mixins";
@import "./abstracts//animations";
@import "./layout/colours";


//Components
@import "./components/dialogs";
@import "./components/menu";
@import "./components/buttons";
@import "./components/spinners";

//Features
@import "./features/response";
@import "./features/survey_response";

//Layout
@import "./layout/header-footer";
@import "./layout/home";
@import "./layout/common";
@import "./layout/buttons";
@import "./layout/dialog";
@import "./layout/popup-menu";
@import "./layout/layout";
@import "./layout/transform";

//Pages
@import "./pages/login";
@import "./pages/dashboard";
@import "./pages/campaign";
@import "./pages/user-management";
@import "./pages/client-setup";
@import "./pages/system-admin";
@import "./pages/response_ai";
@import "./pages/profit-edge";
@import "./pages/askbuddy";
@import "./features/user_settings";
@import "./features/profit_edge_simulation";
@import "./features/guided-tour";
@import "./pages/onboarding";

@import "./components/side_menu_container";
@import "./components/form-control";

// third-party
@import "../../../node_modules/@ctrl/ngx-emoji-mart/picker.css";

//MQ - 03062021 - Gaurav - Moved MQ settings to last
@import "./abstracts/media";
