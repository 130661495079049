/** 05042021 - Gaurav - JIRA-CA-310: Create all mixins here; created one for common action button styles */
@mixin action-icon-button-style($isEnabled) {
  margin: 5px !important;
  width: 30px !important;
  height: 30px !important;
  font-size: 0px !important;

  @if $isEnabled != "enabled" {
    color: $disabled-icon-button;
  }

  mat-icon {
    font-size: 18px;
    @if $isEnabled == "enabled" {
      &:hover {
        color: $all-icons-hover;
      }
    }
  }
}

/** Dynamic font size update */
$mqIterations: 6;
@mixin fontResize($iterations) {
  $i: 1;
  @while $i <= $iterations {
    @media only screen and (min-width: (100px * ($i - 1))) and (max-width: (100px * $i)) {
      .app-tree-node {
        font-size: 7 + $i + px !important;
      }
      .app-tree-node .app-tree-node {
        font-size: 5 + $i + px !important;
      }
    }
    $i: $i + 1;
  }
}
@include fontResize($mqIterations);

@mixin theme-bg-color($theme, $color, $opacity) {
  background: rgba($color, $opacity) !important;
}

@mixin theme-text-color($theme, $color, $opacity) {
  color: rgba($color, $opacity) !important;
}
